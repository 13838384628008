<script setup>
import { ref, computed } from 'vue';
import StringUtility from 'utils/stringUtility';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import { ToastComponent } from 'ui';

const props = defineProps({
  message: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
});

const refToast = ref(null);
const hover = ref(false);
const isLoading = ref(false);
const emit = defineEmits(['modal-module', 'detach-claim']);

const readClass = computed(() => {
  return props.message?.read_time === null ? 'font-bold' : 'font-normal';
});

const formattedDate = computed(() => {
  return StringUtility.formatDate(props.message.created);
});

const assignToClaim = () => {
  emit('modal-module', true);
};

const goToClaim = index => {
  window.open('/navigator/consclaim/details/' + index, '_blank');
};

const detachClaim = (assocs, messageId) => {
  emit('detach-claim', assocs, messageId);
};
</script>

<template>
  <div
    class="flex flex-column cursor-pointer border-b border-black hover:bg-medosync-light-blue px-2 py-4"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <SpinnerLoader v-if="isLoading" />
    <div class="grow">
      <h4 :class="[readClass]">{{ message.author }}</h4>
      <p :class="[readClass]">
        {{ message.content.content ? message.content.content : 'N/A' }}
      </p>
      <h5 class="text-medosync-inactive-grey" :class="[readClass]">
        {{ formattedDate }}
      </h5>
    </div>
    <div class="flex items-center space-x-2">
      <div v-if="message?.assoc?.some(a => a.assoc_type === 'consultantclaim')" class="flex space-x-2">
        <button
          class="rounded-full shadow-md border-2 bg-white text-xs px-8 py-2 whitespace-nowrap"
          :class="[hover ? 'bg-transparent border-black' : 'border-medosync-blue bg-medosync-blue']"
          @click.stop="detachClaim(message.assoc, message.message.id)"
          :data-test-id="`notification-item-${index}-detach-claim-button`"
        >
          Detach
        </button>
        <button
          class="rounded-full shadow-md border-2 bg-medosync-blue text-xs px-8 py-2 whitespace-nowrap"
          :class="[hover ? 'bg-transparent border-black' : 'border-medosync-blue bg-medosync-blue']"
          @click.stop="goToClaim(message.assoc.find(a => a.assoc_type === 'consultantclaim').id)"
          :data-test-id="`notification-item-${index}-go-to-claim-button`"
        >
          Go to claim
        </button>
      </div>
      <div v-else-if="message?.consultant?.id">
        <button
          class="rounded-full shadow-md border-2 bg-medosync-blue text-xs px-8 py-2 whitespace-nowrap"
          :class="[hover ? 'bg-transparent border-black' : 'border-medosync-blue bg-medosync-blue']"
          @click.stop="assignToClaim"
          :data-test-id="`notification-item-${index}-assign-to-claim-button`"
        >
          Assign to claim
        </button>
      </div>
    </div>
    <ToastComponent ref="refToast"></ToastComponent>
  </div>
</template>
