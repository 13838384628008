<script setup lang="ts">
import { defineProps, ref, computed, watch, onMounted } from 'vue';
import { useField } from 'vee-validate';
import InputErrorMessage from './InputErrorMessage.vue';

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  requiredLength: {
    type: Number,
    default: 0
  },
  helperText: {
    type: String,
    default: null
  },
  maxLength: {
    type: Number,
    required: true
  },
  textBold: {
    type: Boolean,
    default: true
  },
  editMode: {
    type: Boolean,
    default: true
  },
  isWarning: {
    type: Boolean,
    default: false
  },
  /*
  Use type "tel" for numeric input on mobile devices because number input doesn't support maxlength attribute
  */
  inputType: {
    type: String,
    default: 'text'
  },
  showErrorMessages: {
    type: Boolean,
    default: true
  }
});

const { value, errorMessage } = useField(() => props.name);

const isMobile = ref(false);
const letterWhitespace = ref(40);
const firstLetterLeftPadding = ref(15);
const fontSize = ref(20);
const letterSpacing = ref(28);

const bgStyle = computed(() => {
  let classStart = `linear-gradient(90deg, rgba(255, 255, 255, 0) 0px, `;
  let classEnd = `rgba(255, 255, 255, 0) 100%)`;
  let classMiddle = '';
  for (let i = 1; i < props.maxLength; i++) {
    classMiddle += `
    rgba(255, 255, 255, 0) ${letterWhitespace.value * i - firstLetterLeftPadding.value}px, 
    rgba(0, 0, 0, 1) ${letterWhitespace.value * i - firstLetterLeftPadding.value}px, 
    rgba(0, 0, 0, 1) ${letterWhitespace.value * i + 1 - firstLetterLeftPadding.value}px, 
    rgba(255, 255, 255, 0) ${letterWhitespace.value * i + 1 - firstLetterLeftPadding.value}px, `;
  }
  return {
    background: classStart + classMiddle + classEnd
  };
});

watch(isMobile, value => {
  if (value) {
    letterWhitespace.value = 30;
    firstLetterLeftPadding.value = 11;
    fontSize.value = 16;
    letterSpacing.value = 20;
  } else {
    letterWhitespace.value = 40;
    firstLetterLeftPadding.value = 15;
    fontSize.value = 20;
    letterSpacing.value = 28;
  }
});

onMounted(() => {
  isMobile.value = window.innerWidth < 640;
});
</script>

<template>
  <div>
    <label
      :for="id"
      class="text-sm sm:text-base block"
      :class="{
        'text-medosync-dark-orange': isWarning && editMode,
        'text-medosync-red': showErrorMessages && errorMessage,
        'font-bold': textBold
      }"
    >
      {{ label }}{{ requiredLength > 0 ? '*' : '' }}
      <i v-if="isWarning && editMode" class="fal fa-circle-exclamation mx-1 text-medosync-dark-orange"></i>
    </label>
    <div
      v-show="editMode"
      class="overflow-hidden border border-medosync-black rounded-md py-2 md:py-3 mt-2 relative h-[42px] md:h-[50px]"
      :class="{
        'border-medosync-dark-orange': isWarning && editMode,
        'border-medosync-red': showErrorMessages && errorMessage,
        'cursor-not-allowed': !editMode
      }"
      :style="{ width: `calc(${maxLength * letterWhitespace}px + ${firstLetterLeftPadding}px + 1px)` }"
    >
      <input
        v-model="value"
        :id="id"
        :name="name"
        :maxlength="maxLength"
        :type="inputType"
        class="input-with-separators"
        :style="{
          width: `${maxLength * letterWhitespace}px`,
          ...bgStyle,
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          left: `${firstLetterLeftPadding}px`
        }"
        :class="{
          'border-medosync-dark-orange': isWarning && editMode,
          'border-medosync-red': showErrorMessages && errorMessage,
          'cursor-not-allowed': !editMode
        }"
        :disabled="!editMode"
      />
    </div>
    <div v-show="!editMode">
      <p :id="id + '-readonly'" class="text-lg text-gray-500 ml-4 mr-6">
        {{ value === '' || value === undefined || value === null ? 'N/A' : value }}
      </p>
    </div>
    <div
      v-if="helperText"
      class="block text-sm font-normal italic mt-1"
      :class="{ 'text-medosync-dark-orange': isWarning && editMode }"
    >
      {{ helperText }}
    </div>
    <InputErrorMessage v-if="showErrorMessages && errorMessage" class="block"
      >Please enter your {{ label }}</InputErrorMessage
    >
  </div>
</template>

<style scoped>
.input-with-separators {
  padding: 0;
  border: none;
  overflow-x: hidden;
  white-space: nowrap;
  position: absolute;
  font-family: monospace;
}
.input-with-separators:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
</style>
