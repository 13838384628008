export const withLoadingVue2 = async (apiCall, loadingRef, options = {}) => {
  const { errorMessage = 'Something went wrong', retryCount = 0, timeout = 30000 } = options;

  loadingRef = true;

  try {
    let attempts = 0;
    while (attempts <= retryCount) {
      try {
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('Operation timed out')), timeout);
        });

        const resultPromise = apiCall();
        const result = await Promise.race([resultPromise, timeoutPromise]);
        return result;
      } catch (error) {
        attempts++;
        if (attempts > retryCount) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  } catch (error) {
    console.error('[API Error]:', error);
    throw new Error(errorMessage);
  } finally {
    loadingRef = false;
  }
};
/*
import { withLoadingVue2 } from '@/util/loadingUtilVue2';

export default {
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    async fetchData() {
      try {
        const result = await withLoadingVue2(
          () => api.getData(),
          this.isLoading,
          { errorMessage: 'Failed to load data' }
        );
        // handle success
      } catch (error) {
        // handle error
      }
    }
  }
}
*/
