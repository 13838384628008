<script setup>
import { ClaimPhase, ConsultantClaimStateEnum } from 'enums';
import { OpenAPIJSON } from 'api/openapi';
import { ref, onMounted, defineEmits, computed, defineProps, watch } from 'vue';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';

const props = defineProps({
  claimTypes: null,
  initialState: {
    type: Object,
    default: () => ({
      consultantId: null,
      claimStatus: null,
      claimTag: null,
      searchString: '',
      activeFiltersCount: 0
    })
  }
});

// Loading wrapper utility
const withLoading = async (apiCall, options = {}) => {
  const { loadingDelay = 300, errorMessage, retryCount = 0, timeout = 30000 } = options;

  let attempts = 0;
  const loadingTimeout = setTimeout(() => {
    isLoading.value = true;
  }, loadingDelay);

  try {
    while (attempts <= retryCount) {
      try {
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('Operation timed out')), timeout);
        });

        const resultPromise = apiCall();
        const result = await Promise.race([resultPromise, timeoutPromise]);
        return { data: result, error: null };
      } catch (error) {
        attempts++;
        if (attempts > retryCount) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  } catch (error) {
    console.error('[API Error]:', error);
    return {
      error: true,
      message: errorMessage || 'Something went wrong. Please try again later.'
    };
  } finally {
    clearTimeout(loadingTimeout);
    isLoading.value = false;
  }
};

// Menu items configuration with their respective states and phases
const menuItems = ref([
  {
    name: 'All Claims',
    link: 'all',
    count: 0,
    active: true,
    info: 'Claims in any state'
  },
  {
    name: 'Draft Claims',
    link: ConsultantClaimStateEnum.DRAFT,
    count: 0,
    active: true,
    info: 'New claims',
    phase: ClaimPhase.PRE_SUBMIT
  },
  {
    name: 'Consultant approval requested',
    link: ConsultantClaimStateEnum.READY_FOR_CONSULTANT_APPROVAL,
    count: 0,
    active: true,
    info: 'Prepared claims awaiting sign-off from the consultant',
    phase: ClaimPhase.PRE_SUBMIT
  },
  {
    name: 'Rejected By Consultant',
    link: ConsultantClaimStateEnum.REJECTED_BY_CONSULTANT_FOR_AMENDMENT,
    count: 0,
    active: true,
    info: 'Claims that the consultant has rejected for signing and may require further changes.',
    phase: ClaimPhase.PRE_SUBMIT
  },
  {
    name: 'Consultant approved',
    link: ConsultantClaimStateEnum.CONSULTANT_APPROVED,
    count: 0,
    active: true,
    info: 'Claims that have been signed-off by the consultant. A PDF preview will now display their signature and signing-date.',
    phase: ClaimPhase.PRE_SUBMIT
  },
  {
    name: 'Ready for submission',
    link: ConsultantClaimStateEnum.READY_FOR_SEND_TO_INSURER,
    count: 0,
    active: true,
    info: 'Claims that have been signed-off by the consultant that have been marked as complete and as such now have a immutable invoice with real invoice number. The claim-pack can be downloaded',
    phase: ClaimPhase.PRE_SUBMIT
  },
  {
    name: 'Submitted',
    link: ConsultantClaimStateEnum.SENT_TO_INSURER,
    count: 0,
    active: true,
    info: 'Claims that have been submitted to the insurer/entered into Claimsure.',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'Pended',
    link: ConsultantClaimStateEnum.PENDED_WITH_HOSPITAL,
    count: 0,
    active: true,
    info: 'Submitted claims returned by the insurance company for which it has asked for additional information before adjudication can be performed.',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'Rejected',
    link: ConsultantClaimStateEnum.REJECTED,
    count: 0,
    active: true,
    info: 'Claims that have been adjudicated by insurance company and rejected for payment.',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'For Appeal',
    link: ConsultantClaimStateEnum.FOR_APPEAL,
    count: 0,
    active: true,
    info: 'Claims rejected by the insurance company that the hospital is going to appeal.',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'Part Paid',
    link: ConsultantClaimStateEnum.PART_PAID,
    count: 0,
    active: true,
    info: 'Claims that the insurer has not paid 100% of the invoice.',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'Over-paid',
    link: ConsultantClaimStateEnum.OVER_PAID,
    count: 0,
    active: true,
    info: 'Claims where the remittance total is greater than the invoice total, which likely indicates an error in the remittances',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'Paid in Full',
    link: ConsultantClaimStateEnum.PAID_IN_FULL,
    count: 0,
    active: true,
    info: 'Claims for which the sum of payments received from the insurer matches the invoiced total',
    phase: ClaimPhase.POST_SUBMIT
  },
  {
    name: 'Written Off - Appeal Denied',
    link: ConsultantClaimStateEnum.WRITE_OFF_APPEAL_DENIED,
    count: 0,
    active: true,
    phase: ClaimPhase.FINISHED
  },
  {
    name: 'Written Off - Rejected',
    link: ConsultantClaimStateEnum.WRITE_OFF_REJECTED,
    count: 0,
    active: true,
    phase: ClaimPhase.FINISHED
  },
  {
    name: 'Written Off - Billed Incorrectly',
    link: ConsultantClaimStateEnum.WRITE_OFF_BILLED_INCORRECTLY,
    count: 0,
    active: true,
    phase: ClaimPhase.FINISHED
  },
  {
    name: 'Manually Rejected',
    link: ConsultantClaimStateEnum.MANUAL_REJECTED,
    count: 0,
    active: true,
    info: 'Claims rejected for further processing and will not be submitted to the insurer.',
    phase: ClaimPhase.FINISHED
  }
]);

const emit = defineEmits(['applied-filters']);

// State management
const isLoading = ref(false);
const claimStates = ref({});
const selectedItem = ref(menuItems.value[0]);
const claimTags = ref([]);
const selectedTag = ref(null);
const consultants = ref([]);
const selectedConsultants = ref([]);
const selectedContracts = ref([]);
const filters = ref([]);
const searchQuery = ref('');
const medic = ref(null);
const username = ref('');

// Store contracts data
const contracts = ref([]);

// Pagination state
const itemsPerColumn = ref(10);
const showMoreVisible = ref(false);

// Watch for changes in selected items and update filters immediately
watch([selectedItem, selectedTag, selectedConsultants], () => {
  filters.value = {
    claimStatus: selectedItem.value,
    claimTag: selectedTag.value,
    consultants: selectedConsultants.value.map(consultant => consultant.medic.ro.consultant_id)
  };
});

// Get unique insurer IDs
const uniqueInsurerIds = computed(() => {
  if (!contracts.value?.items) return [];
  return [...new Set(contracts.value.items.map(item => item.insurer_id))];
});

// Filter and paginate contracts
const filteredContracts = computed(() => {
  if (!contracts.value?.items) return [];
  const allContracts = contracts.value.items;

  // Remove duplicates based on insurer_name
  const uniqueContracts = allContracts.reduce((acc, current) => {
    const exists = acc.find(item => item.insurer_name === current.insurer_name);
    if (!exists) {
      acc.push(current);
    }
    return acc;
  }, []);

  const filtered = !searchQuery.value.trim()
    ? uniqueContracts
    : uniqueContracts.filter(contract => contract.insurer_name.toLowerCase().includes(searchQuery.value.toLowerCase()));

  return filtered.slice(0, itemsPerColumn.value);
});

// Filter consultants based on search query
const filteredConsultants = computed(() => {
  if (!searchQuery.value.trim()) {
    return consultants.value;
  }
  return consultants.value.filter(consultant =>
    consultant.medic.ro.full_name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

// Modified computed properties for dynamic columns
const columnConsultants = computed(() => {
  const filtered = filteredConsultants.value;
  const total = filtered.length;
  const columns = [];

  // Calculate items per column (now 2 columns)
  for (let i = 0; i < 2; i++) {
    const start = i * itemsPerColumn.value;
    const end = start + itemsPerColumn.value;
    columns.push(filtered.slice(start, end));
  }

  // Update show more visibility
  showMoreVisible.value = total > itemsPerColumn.value * 2;

  return columns;
});

// Update showMoreVisible computation to consider both consultants and contracts
const shouldShowMore = computed(() => {
  const totalConsultants = filteredConsultants.value.length;
  const totalContracts = contracts.value?.items?.length || 0;

  return totalConsultants > itemsPerColumn.value * 2 || totalContracts > itemsPerColumn.value;
});

// Check if all consultants are selected
const allConsultantsSelected = computed(() => {
  return selectedConsultants.value.length === consultants.value.length;
});

// Check if all contracts are selected
const allContractsSelected = computed(() => {
  if (!contracts.value?.items) return false;
  const uniqueIds = uniqueInsurerIds.value;
  return (
    selectedContracts.value.length === uniqueIds.length && uniqueIds.every(id => selectedContracts.value.includes(id))
  );
});

// Function to handle show more click
const handleShowMore = () => {
  if (itemsPerColumn.value === 10) {
    itemsPerColumn.value = 15;
  } else {
    itemsPerColumn.value += 5;
  }
};

// API Calls
const searchContracts = async () => {
  const oapi = new OpenAPIJSON();
  const { data, error } = await withLoading(() => oapi.searchInsurances(), {
    errorMessage: 'Error fetching insurers',
    retryCount: 1
  });

  if (error) return;
  contracts.value = data;
};

const getClaimCount = async () => {
  const oapi = new OpenAPIJSON();
  const { data, error } = await withLoading(
    () => (props.claimTypes === 'claim' ? oapi.getClaimCount() : oapi.getCompositeClaimCount()),
    {
      errorMessage: 'Error fetching claim count',
      retryCount: 1
    }
  );

  if (error) return;
  claimStates.value = data;
};

const getClaimTagsCount = async () => {
  const claimState = selectedItem.value && selectedItem.value.link !== 'all' ? [selectedItem.value.link] : null;
  const oapi = new OpenAPIJSON();

  const { data, error } = await withLoading(
    () =>
      props.claimTypes === 'claim' ? oapi.getClaimTagsCount(null, null, claimState) : oapi.getCompositeClaimTagsCount(),
    {
      errorMessage: 'Error fetching claim tags',
      retryCount: 1
    }
  );

  if (error) return;

  if (data.length > 0) {
    for (const item of data) {
      item.on = false;
    }
  }

  const allTag = {
    name: 'all',
    count: -1,
    on: true,
    active: true
  };

  data.splice(0, 0, allTag);
  claimTags.value = data;

  if (!selectedTag.value && claimTags.value.length > 0) {
    selectedTag.value = claimTags.value[0];
  }
};

const fetchMedics = async () => {
  const oapi = new OpenAPIJSON();
  const { data, error } = await withLoading(() => oapi.searchDoctors(null, null, null, true, false), {
    errorMessage: 'Error fetching doctors',
    retryCount: 1
  });

  if (error) return;
  consultants.value = data;

  if (props.initialState.consultantId?.length > 0) {
    const savedConsultants = consultants.value.filter(consultant =>
      props.initialState.consultantId.includes(consultant.medic.ro.consultant_id)
    );
    selectedConsultants.value = savedConsultants;
  }
};

// Handle contract selection
const handleContractClick = contract => {
  const index = selectedContracts.value.indexOf(contract.insurer_id);
  if (index === -1) {
    selectedContracts.value.push(contract.insurer_id);
  } else {
    selectedContracts.value.splice(index, 1);
  }
};

// Toggle all contracts selection
const toggleAllContracts = () => {
  if (allContractsSelected.value) {
    selectedContracts.value = [];
  } else {
    selectedContracts.value = uniqueInsurerIds.value;
  }
};

// Watch for changes in selected items and update filters immediately
watch([selectedItem, selectedTag, selectedConsultants], () => {
  filters.value = {
    claimStatus: selectedItem.value,
    claimTag: selectedTag.value,
    consultants: selectedConsultants.value.map(consultant => consultant.medic.ro.consultant_id)
  };
});

// Watch for contract changes
watch(selectedContracts, () => {
  if (selectedContracts.value.length > 0) {
    filters.value = {
      ...filters.value,
      insurers: selectedContracts.value
    };
  }
});

// Load user preferences
const listPreference = () => {
  const ls = JSON.parse(localStorage.getItem('preferences'));
  const tagsLayout = ls?.tagsLayout;
  const menuLayout = ls?.menuLayout;

  if (tagsLayout) {
    claimTags.value.forEach(tag => {
      tagsLayout.forEach(item => {
        if (tag.name.toLowerCase() === item.name.toLowerCase()) {
          tag.active = item.active;
        }
      });
    });
  }

  if (menuLayout) {
    menuItems.value.forEach(m => {
      menuLayout.forEach(item => {
        if (m.name.toLowerCase() === item.name.toLowerCase()) {
          m.active = item.active;
        }
      });
    });
  }
};

// Initial data fetch
const fetch = async () => {
  await getClaimCount();
  await getClaimTagsCount();

  let object = {};
  let count = 0;
  object = claimStates.value;

  for (const property in object) {
    menuItems.value.forEach(item => {
      if (`${property}` === item.link) {
        item.count = parseInt(`${object[property]}`);
      }
    });
    count += parseInt(`${object[property]}`);
  }

  menuItems.value.find(item => item.link === 'all').count = count;
};

// Event Handlers
const handleMenuItemClick = async item => {
  selectedItem.value = item;
  await getClaimTagsCount();
};

const handleTagClick = tag => {
  selectedTag.value = tag;
};

const toggleAllConsultants = () => {
  if (allConsultantsSelected.value) {
    selectedConsultants.value = [];
  } else {
    selectedConsultants.value = [...consultants.value];
  }
};

const handleConsultantClick = consultant => {
  const index = selectedConsultants.value.findIndex(c => c.medic.ro.full_name === consultant.medic.ro.full_name);

  if (index === -1) {
    selectedConsultants.value.push(consultant);
  } else {
    selectedConsultants.value.splice(index, 1);
  }
};

// Apply selected filters
const applyFilters = () => {
  const consultantIds = selectedConsultants.value.map(item => item.medic.ro.consultant_id);

  filters.value = {
    claimStatus: selectedItem.value,
    claimTag: selectedTag.value,
    consultants: consultantIds,
    insurers: selectedContracts.value
  };

  emit('applied-filters', filters.value);
};

// Initialize component
onMounted(async () => {
  await searchContracts();
  await fetch();
  listPreference();
  await fetchMedics();

  // Restore selected claim status
  if (props.initialState.claimStatus) {
    const savedStatus = menuItems.value.find(item => item.link === props.initialState.claimStatus[0]);
    if (savedStatus) {
      selectedItem.value = savedStatus;
      await getClaimTagsCount();
    }
  }

  // Restore selected tag
  if (props.initialState.claimTag) {
    const savedTag = claimTags.value.find(tag => tag.name === props.initialState.claimTag[0]);
    if (savedTag) {
      selectedTag.value = savedTag;
    }
  }

  try {
    const storedConfig = localStorage.getItem('me');
    const config = JSON.parse(storedConfig);
    medic.value = config.medic;
    username.value = config.username;
  } catch (error) {
    // Handle config parsing error silently
  }
});

defineExpose({
  applyFilters
});
</script>

<template>
  <div
    class="bg-white border border-medosync-light-grey w-full absolute top-20 p-3 shadow z-50 left-[-1px]"
    data-test-id="claims-filter-container"
  >
    <SpinnerLoader v-if="isLoading" />
    <!-- Search Section -->
    <div v-if="!medic" class="medosync-search-section">
      <div class="medosync-search-col-left"></div>
      <div class="medosync-search-col-right">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search list for consultant name or insurer"
          class="medosync-search-input"
          data-test-id="consultant-search-input"
        />
      </div>
    </div>

    <!-- Main Grid Layout -->
    <div class="grid grid-cols-5 gap-5">
      <!-- Claim Status Section -->
      <div data-test-id="claim-status-section">
        <h5 class="text-xs font-bold mb-4" data-test-id="claim-status-title">Claim status</h5>
        <div v-for="(item, index) in menuItems" :key="index">
          <div
            v-if="item.active"
            class="nav-item"
            :class="{ 'nav-item-active': selectedItem === item }"
            :title="item.info"
            @click="handleMenuItemClick(item)"
            :data-test-id="`claim-status-item-${item.link}`"
          >
            <div class="item-name" :data-test-id="`claim-status-name-${item.link}`">
              {{ item.name }}
            </div>
            <div class="item-count" :data-test-id="`claim-status-count-${item.link}`">
              {{ item.count }}
            </div>
          </div>
        </div>
      </div>

      <!-- Claim Tags Section -->
      <div data-test-id="claim-tags-section">
        <h5 class="text-xs font-bold mb-4" data-test-id="claim-tags-title">Claim Tags</h5>
        <div v-for="(tag, index) in claimTags" :key="index">
          <div
            class="nav-item"
            :class="{ 'tag-item-active': selectedTag === tag }"
            @click="handleTagClick(tag)"
            :data-test-id="`claim-tag-item-${tag.name}`"
          >
            <div class="item-name" :data-test-id="`claim-tag-name-${tag.name}`">
              {{ tag.name }}
            </div>
            <div v-if="tag.count > 0" class="item-count" :data-test-id="`claim-tag-count-${tag.name}`">
              {{ tag.count }}
            </div>
          </div>
        </div>
      </div>

      <!-- Consultant Section -->
      <div class="col-span-3" data-test-id="consultants-section">
        <div class="grid grid-cols-3 gap-4">
          <!-- Consultants Title -->
          <div class="col-span-2">
            <h5 class="text-xs font-bold mb-4" data-test-id="consultants-title">Consultant</h5>
          </div>
          <!-- Contracts Title -->
          <div>
            <h5 class="text-xs font-bold mb-4" data-test-id="contracts-title">Insurers</h5>
          </div>
        </div>

        <!-- Single Medic View -->
        <div v-if="medic" class="grid grid-cols-3 gap-4">
          <!-- Single Medic Column -->
          <div class="col-span-2">
            <div class="space-y-2">
              <div class="flex items-center break-inside-avoid mb-2">
                <div class="medosync-checkbox">
                  <input type="checkbox" :checked="true" disabled data-test-id="medic-checkbox" />
                  <div class="checkbox-box checked disabled">
                    <svg
                      class="checkbox-icon checked"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      data-test-id="medic-checkbox-icon"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                </div>
                <label class="text-sm ml-4" data-test-id="medic-username">{{ username }}</label>
              </div>
            </div>
          </div>

          <!-- Insurers Column -->
          <div class="space-y-2 min-h-[40px]" data-test-id="contracts-column">
            <!-- All Contracts Option -->
            <div class="flex items-center break-inside-avoid mb-2" data-test-id="all-contracts-container">
              <div class="medosync-checkbox">
                <input
                  type="checkbox"
                  :checked="allContractsSelected"
                  @change="toggleAllContracts"
                  data-test-id="all-contracts-checkbox"
                />
                <div class="checkbox-box" :class="{ checked: allContractsSelected }">
                  <svg
                    v-if="allContractsSelected"
                    class="checkbox-icon checked"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    data-test-id="all-contracts-checkbox-icon"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
              <label class="text-sm ml-4" data-test-id="all-contracts-label">All Insurers</label>
            </div>

            <!-- Individual Contracts -->
            <div
              v-for="contract in filteredContracts"
              :key="contract.insurer_id"
              class="flex items-center break-inside-avoid mb-2"
              :data-test-id="`contract-item-${contract.insurer_id}`"
            >
              <div class="medosync-checkbox">
                <input
                  type="checkbox"
                  :checked="selectedContracts.includes(contract.insurer_id)"
                  @change="handleContractClick(contract)"
                  :data-test-id="`contract-checkbox-${contract.insurer_id}`"
                />
                <div
                  class="checkbox-box"
                  :class="{
                    checked: selectedContracts.includes(contract.insurer_id)
                  }"
                >
                  <svg
                    v-if="selectedContracts.includes(contract.insurer_id)"
                    class="checkbox-icon checked"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    :data-test-id="`contract-checkbox-icon-${contract.insurer_id}`"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
              <label class="text-sm ml-4" :data-test-id="`contract-name-${contract.insurer_id}`">
                {{ contract.insurer_name }}
              </label>
            </div>
          </div>
        </div>

        <!-- Multiple Consultants and Contracts View -->
        <div v-else class="grid grid-cols-3 gap-4">
          <!-- Consultants Columns (2 columns) -->
          <div class="col-span-2">
            <!-- Consultants Grid -->
            <div class="grid grid-cols-2 gap-4" data-test-id="consultants-grid">
              <div
                v-for="(column, columnIndex) in columnConsultants.slice(0, 2)"
                :key="columnIndex"
                class="space-y-2 min-h-[40px]"
                :data-test-id="`consultants-column-${columnIndex + 1}`"
              >
                <!-- All Consultants Option (only in first column) -->
                <div
                  v-if="columnIndex === 0"
                  class="flex items-center break-inside-avoid mb-2"
                  data-test-id="all-consultants-container"
                >
                  <div class="medosync-checkbox">
                    <input
                      type="checkbox"
                      :checked="allConsultantsSelected"
                      @change="toggleAllConsultants"
                      data-test-id="all-consultants-checkbox"
                    />
                    <div class="checkbox-box" :class="{ checked: allConsultantsSelected }">
                      <svg
                        v-if="allConsultantsSelected"
                        class="checkbox-icon checked"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        data-test-id="all-consultants-checkbox-icon"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                  </div>
                  <label class="text-sm ml-4" data-test-id="all-consultants-label">All Consultants</label>
                </div>

                <!-- Individual Consultants -->
                <div
                  v-for="consultant in column"
                  :key="consultant.medic.ro.consultant_id"
                  class="flex items-center break-inside-avoid mb-2"
                  :data-test-id="`consultant-item-${consultant.medic.ro.consultant_id}`"
                >
                  <div class="medosync-checkbox">
                    <input
                      type="checkbox"
                      :checked="selectedConsultants.some(c => c.medic.ro.full_name === consultant.medic.ro.full_name)"
                      @change="handleConsultantClick(consultant)"
                      :data-test-id="`consultant-checkbox-${consultant.medic.ro.consultant_id}`"
                    />
                    <div
                      class="checkbox-box"
                      :class="{
                        checked: selectedConsultants.some(c => c.medic.ro.full_name === consultant.medic.ro.full_name)
                      }"
                    >
                      <svg
                        v-if="selectedConsultants.some(c => c.medic.ro.full_name === consultant.medic.ro.full_name)"
                        class="checkbox-icon checked"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        :data-test-id="`consultant-checkbox-icon-${consultant.medic.ro.consultant_id}`"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                  </div>
                  <label class="text-sm ml-4" :data-test-id="`consultant-name-${consultant.medic.ro.full_name}`">
                    {{ consultant.medic.ro.full_name }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- Contracts Column -->
          <div class="space-y-2 min-h-[40px]" data-test-id="contracts-column">
            <!-- All Contracts Option -->
            <div class="flex items-center break-inside-avoid mb-2" data-test-id="all-contracts-container">
              <div class="medosync-checkbox">
                <input
                  type="checkbox"
                  :checked="allContractsSelected"
                  @change="toggleAllContracts"
                  data-test-id="all-contracts-checkbox"
                />
                <div class="checkbox-box" :class="{ checked: allContractsSelected }">
                  <svg
                    v-if="allContractsSelected"
                    class="checkbox-icon checked"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    data-test-id="all-contracts-checkbox-icon"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
              <label class="text-sm ml-4" data-test-id="all-contracts-label">All Insurers</label>
            </div>

            <!-- Individual Contracts -->
            <div
              v-for="contract in filteredContracts"
              :key="contract.insurer_id"
              class="flex items-center break-inside-avoid mb-2"
              :data-test-id="`contract-item-${contract.insurer_id}`"
            >
              <div class="medosync-checkbox">
                <input
                  type="checkbox"
                  :checked="selectedContracts.includes(contract.insurer_id)"
                  @change="handleContractClick(contract)"
                  :data-test-id="`contract-checkbox-${contract.insurer_id}`"
                />
                <div
                  class="checkbox-box"
                  :class="{
                    checked: selectedContracts.includes(contract.insurer_id)
                  }"
                >
                  <svg
                    v-if="selectedContracts.includes(contract.insurer_id)"
                    class="checkbox-icon checked"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    :data-test-id="`contract-checkbox-icon-${contract.insurer_id}`"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
              <label class="text-sm ml-4" :data-test-id="`contract-name-${contract.insurer_id}`">
                {{ contract.insurer_name }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Show More Button -->
    <div v-if="shouldShowMore" class="text-center mt-4">
      <button
        @click="handleShowMore"
        class="text-medosync-black text-sm underline hover:opacity-75 transition-opacity"
        data-test-id="show-more-button"
      >
        Show More
      </button>
    </div>
  </div>
</template>

<style>
/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-100 rounded;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300 rounded;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400;
}
</style>
