<script setup>
import { ref } from 'vue';
import { OpenAPIJSON } from 'api';
import { useRoute } from 'vue-router';

import NotificationsList from '@/components/notifications/NotificationsListCcp.vue';
import NotificationsMessage from '@/components/notifications/NotificationsMessage.vue';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import { ToastComponent } from 'ui';
import MessageToClaimModal from '@/views/notifications/MessageToClaimModal.vue';
import DefaultModal from '@/components/claim-forms/components/DefaultModal.vue';
import ConsultantDropdown from './FilterDropdown.vue';
import DatePickerDashboard from './DatePickerDropdown.vue';

// Add refs for child components
const datePickerRef = ref(null);
const consultantDropdownRef = ref(null);

// Loading wrapper utility
const withLoading = async (apiCall, options = {}) => {
  const { loadingDelay = 300, errorMessage, retryCount = 0, timeout = 30000 } = options;

  let attempts = 0;
  const loadingTimeout = setTimeout(() => {
    isLoading.value = true;
  }, loadingDelay);

  try {
    while (attempts <= retryCount) {
      try {
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('Operation timed out')), timeout);
        });

        const resultPromise = apiCall();
        const result = await Promise.race([resultPromise, timeoutPromise]);
        return { data: result, error: null };
      } catch (error) {
        attempts++;
        if (attempts > retryCount) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  } catch (error) {
    console.error('[API Error]:', error);
    return {
      error: true,
      message: errorMessage || 'Something went wrong. Please try again later.'
    };
  } finally {
    clearTimeout(loadingTimeout);
    isLoading.value = false;
  }
};

const route = useRoute();
const notificationId = ref(route?.params?.id);
const notificationHospitalAppointmentId = ref(route?.params?.hospitalAppointmentId);
const openApi = new OpenAPIJSON();
const isLoading = ref(false);
const refToast = ref(null);
const shownMessages = ref([]);
const messagesIdx = ref(1);
const messagesPageSize = ref(19);
const stopLazyLoad = ref(false);
const selectedMessage = ref(null);
const totalMessages = ref(0);
const searchTerm = ref('');
const messageParams = ref({
  unread: null,
  draft: null,
  notes: null,
  assoc: null,
  sort_by: 'created',
  desc: true,
  sent: false,
  date_sent: null,
  recipients: null,
  pagination: {
    start_idx: 1,
    end_idx: 20
  }
});

const openModal = ref(false);
const openDetachModal = ref(false);
const detachAssoc = ref(null);
const detachMessageId = ref(null);
const dateFilter = ref(null);
const statusFilter = ref(null);
const consultantFilter = ref(null);

const buildMessageParams = (params = {}) => {
  // Start with the existing messageParams
  const newParams = { ...messageParams.value };

  // Add any new params passed in
  Object.assign(newParams, params);

  // Update pagination
  newParams.pagination = {
    start_idx: messagesIdx.value,
    end_idx: messagesIdx.value + messagesPageSize.value
  };

  // Handle status filters
  if (statusFilter.value && statusFilter.value.length > 0) {
    if (statusFilter.value.includes('all')) {
      newParams.unread = null;
      newParams.assoc = null;
    } else {
      // Handle unread/read status
      if (statusFilter.value.includes('unread') && !statusFilter.value.includes('read')) {
        newParams.unread = true;
      } else if (statusFilter.value.includes('read') && !statusFilter.value.includes('unread')) {
        newParams.unread = false;
      } else {
        newParams.unread = null;
      }

      // Handle assigned/not_assigned status
      if (statusFilter.value.includes('assigned') && statusFilter.value.includes('not_assigned')) {
        newParams.assoc = null;
      } else if (statusFilter.value.includes('assigned')) {
        newParams.assoc = [
          {
            assoc_type: 'consultantclaim',
            type: 'any'
          }
        ];
      } else if (statusFilter.value.includes('not_assigned')) {
        newParams.assoc = [
          {
            assoc_type: 'consultantclaim',
            type: 'none'
          }
        ];
      }
    }
  }

  // Handle date filter
  if (dateFilter.value) {
    newParams.date_sent = {
      start: dateFilter.value.start,
      end: dateFilter.value.end
    };
  }

  // Handle consultant filter
  if (consultantFilter.value?.consultants?.length > 0) {
    newParams.recipients = [
      {
        type: 'idlist',
        ids: consultantFilter.value.consultants,
        message_recipient: 'consultantadmin'
      }
    ];
  }

  return newParams;
};
const loadMessages = async (params = {}) => {
  resetMessages();
  if (notificationId.value) {
    const { data, error } = await withLoading(
      () => openApi.claim_consultant_message_list(parseInt(notificationId.value)),
      {
        errorMessage: 'Error loading messages',
        retryCount: 1,
        loadingDelay: 300
      }
    );

    if (error) {
      return refToast.value.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
    }

    shownMessages.value = data.items;
    totalMessages.value = data.total_items;
    messageParams.value = {
      unread: false,
      assoc: [{ assoc_type: 'consultantclaim', type: 'any' }],
      sort_by: 'created',
      desc: true,
      pagination: { start_idx: 1, end_idx: 20 },
      claimId: notificationHospitalAppointmentId.value
    };
    return;
  }

  try {
    isLoading.value = true;
    const requestParams = buildMessageParams(params);

    const { data, error } = await withLoading(() => openApi.message_list(requestParams), {
      errorMessage: 'Error loading messages',
      retryCount: 1,
      loadingDelay: 300
    });

    if (error) {
      return refToast.value.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
    }

    shownMessages.value = data.items;
    totalMessages.value = data.total_items;
    if (shownMessages.value.length >= data.total_items) {
      stopLazyLoad.value = true;
    }
    messagesIdx.value += messagesPageSize.value + 1;
    messageParams.value = requestParams;
  } catch (error) {
    return refToast.value.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
  } finally {
    isLoading.value = false;
  }
};

const loadMoreMessages = async () => {
  if (stopLazyLoad.value || notificationId.value) return;

  messageParams.value.pagination = {
    start_idx: messagesIdx.value,
    end_idx: messagesIdx.value + messagesPageSize.value
  };

  const { data, error } = await withLoading(() => openApi.message_list(messageParams.value), {
    errorMessage: 'Error loading more messages',
    retryCount: 1,
    loadingDelay: 300
  });

  if (error) {
    return refToast.value.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
  }

  shownMessages.value = [...shownMessages.value, ...data.items];
  if (shownMessages.value.length >= data.total_items) {
    stopLazyLoad.value = true;
  }
  messagesIdx.value += messagesPageSize.value + 1;
};

const resetMessages = () => {
  shownMessages.value = [];
  messagesIdx.value = 1;
  selectedMessage.value = null;
  stopLazyLoad.value = false;
  totalMessages.value = 0;
};

const selectMessage = async (id, index, openAssignToClaimModal) => {
  const { data: messageData, error: messageError } = await withLoading(
    async () => {
      try {
        return await openApi.message_get({ message_id: id, include_attachment_data: true });
      } catch {
        return await openApi.message_get({ message_id: id, include_attachment_data: false });
      }
    },
    {
      errorMessage: 'Error selecting message',
      retryCount: 1,
      loadingDelay: 300
    }
  );

  if (messageError) {
    return refToast.value.showToast(messageError, 'fa-solid fa-triangle-exclamation', 'error');
  }

  selectedMessage.value = messageData;
  selectedMessage.value.consultantId = shownMessages.value[index].consultant?.id || null;
  selectedMessage.value.author = shownMessages.value[index].author;
  shownMessages.value[index].read_time = new Date().toISOString();

  const { error: markError } = await withLoading(() => openApi.message_mark_seen(id), {
    errorMessage: 'Error marking message as seen',
    retryCount: 1,
    loadingDelay: 300
  });

  if (markError) {
    return refToast.value.showToast(markError, 'fa-solid fa-triangle-exclamation', 'error');
  }

  if (openAssignToClaimModal) openModal.value = true;
};

const closeMessage = () => {
  selectedMessage.value = null;
};

const detachClaim = async () => {
  const { error } = await withLoading(() => openApi.message_remove_assoc(detachMessageId.value, detachAssoc.value), {
    errorMessage: 'Error detaching claim',
    retryCount: 1,
    loadingDelay: 300
  });

  if (error) {
    return refToast.value.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
  }

  refToast.value.showToast('Message successfully detached.', 'fas fa-check', 'success');
  updateRemovedDetach(detachMessageId.value, detachAssoc.value);
  handleCloseDetachModal();
};

const handleOpenDetachModal = (assocs, messageId) => {
  detachAssoc.value = assocs;
  detachMessageId.value = messageId;
  openDetachModal.value = true;
};

const handleCloseDetachModal = () => {
  openDetachModal.value = false;
  detachAssoc.value = null;
  detachMessageId.value = null;
};

const updateRemovedDetach = (messageID, targetIds) => {
  const messageIndex = shownMessages.value.findIndex(message => message.message.id === messageID);
  if (messageIndex === -1) {
    return;
  }
  const filteredAssoc = shownMessages.value[messageIndex].assoc.filter(assocItem => {
    return !targetIds.some(target => assocItem.id === target.id && assocItem.assoc_type === target.assoc_type);
  });
  shownMessages.value[messageIndex].assoc = filteredAssoc;
};

const showToastMessage = ({ message, icon, type }) => {
  refToast.value.showToast(message, icon, type);
};

const handleDateFilterUpdate = date => {
  dateFilter.value = date;
};

const handleFilters = ({ statuses, consultants }) => {
  statusFilter.value = statuses;
  consultantFilter.value = { consultants };
};

const clearSearch = () => {
  searchTerm.value = '';
};

const resetFilters = () => {
  // Reset date picker
  if (datePickerRef.value) {
    datePickerRef.value.handleReset();
  }

  // Reset consultant dropdown
  if (consultantDropdownRef.value) {
    consultantDropdownRef.value.handleReset();
  }

  // Reset filters
  dateFilter.value = null;
  statusFilter.value = null;
  consultantFilter.value = null;

  // Reset search if it exists
  searchTerm.value = '';

  // Reset message parameters to default state
  messageParams.value = {
    unread: null,
    draft: null,
    notes: null,
    assoc: null,
    sort_by: 'created',
    desc: true,
    sent: false,
    date_sent: null,
    recipients: null,
    pagination: {
      start_idx: 1,
      end_idx: 20
    }
  };

  // Call loadMessages with empty params to ensure no filters are applied
  loadMessages({});
};

// Initialize the component
loadMessages();
</script>

<template>
  <div class="h-screen flex flex-col py-8 px-6">
    <h2 class="text-4xl font-bold text-medosync-violet-highlight mb-10">Notification Centre</h2>

    <!-- Filters aligned horizontally -->
    <div class="flex justify-between gap-4 mb-4">
      <div class="flex gap-4 flex-grow">
        <DatePickerDashboard ref="datePickerRef" @update:dateFilter="handleDateFilterUpdate" class="w-1/1" />
        <ConsultantDropdown ref="consultantDropdownRef" @applied-filters="handleFilters" class="w-1/1" />
      </div>
      <button
        class="underline focus:outline-none"
        type="button"
        data-test-id="reset-filters-button"
        @click="resetFilters"
      >
        Reset All
      </button>
      <button
        @click="loadMessages"
        class="rounded-full shadow-md border-2 bg-medosync-light-blue text-xs px-8 py-2 whitespace-nowrap"
      >
        Apply
      </button>
    </div>

    <h2 class="text-2xl font-bold text-medosync-black mb-4">{{ totalMessages }} messages from consultants</h2>
    <!-- Search box (commented out but preserved) -->
    <!-- <div class="relative flex items-center w-1/3">
      <div class="medosync-search-box">
        <input
          data-test-id="search-input"
          type="text"
          v-model="searchTerm"
          placeholder="Search for key words, procedure code"
        />
        <i
          v-if="searchTerm"
          class="fas fa-times clear-icon"
          data-test-id="clear-search-button"
          @click="clearSearch"
        ></i>
        <div class="search-icon-container" data-test-id="search-button">
          <i class="fas fa-search"></i>
        </div>
      </div>
    </div> -->

    <div class="flex grow min-h-0 gap-x-4">
      <NotificationsList
        :class="[selectedMessage ? 'w-1/2' : 'w-full']"
        :messages="shownMessages"
        :selected-message="selectedMessage"
        @select-message="selectMessage"
        @load-more-messages="loadMoreMessages"
        @detach-claim="handleOpenDetachModal"
      />

      <NotificationsMessage
        v-if="selectedMessage"
        :message="selectedMessage"
        @close-message="closeMessage"
        class="w-1/2"
      />
    </div>

    <MessageToClaimModal
      v-if="openModal"
      @update-messages="shownMessages = $event"
      @modal-module-off="openModal = false"
      @show-toast-message="showToastMessage"
      :messages="selectedMessage"
      :allMessages="shownMessages"
    />

    <DefaultModal
      :showManageModal="openDetachModal"
      title="Detach this message from the assigned claim?"
      text="If you detach this message from the assigned claim you only delete the connection between them."
      subtext="All manually added data into the claim form will still be available."
      primaryButtonText="Detach message from claim"
      secondaryButtonText="Cancel"
      @primary-changes="detachClaim"
      @secondary-changes="handleCloseDetachModal"
      @close-modal="handleCloseDetachModal"
    />

    <SpinnerLoader v-if="isLoading" />
    <ToastComponent ref="refToast" />
  </div>
</template>
