<script setup lang="ts">
import { ref, computed } from 'vue';
import { OpenAPIJSON } from 'api/openapi';
import { BaseModal } from 'ui';
import { ToastComponent } from 'ui';
import 'vue-datepicker-next/index.css';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import DashboardContracts from '@/views/user-management/DashboardContracts.vue';

// Define props with types and defaults
// The interface defines the expected types of the props received from the parent component.
interface Props {
  appointmentId: string; // ID for the appointment
  insurerName: string | null; // Name of the insurer (nullable)
  insurerId: number | null; // ID of the insurer (nullable)
  appId: string; // ID for the application
}
const props = defineProps<Props>(); // Define props using the interface

// Emit event for closing the modal
// The emit function is typed to accept the 'close' event.

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'restart'): void;
}>();

// Reference for the toast notification component
// This ref will store the instance of the ToastComponent to allow triggering toast messages.
const toast = ref<InstanceType<typeof ToastComponent> | null>(null);

// Reactive state variables with appropriate types
// These are reactive variables that will be used in the component.
interface Contract {
  contract_name: string;
  valid_from: string;
  [key: string]: any; // Additional properties can be added dynamically
}

const insurerData = ref<Contract[] | null>(null); // Holds the list of contracts from the API
const insurerContractSelected = ref<Contract | null>(null); // Holds the selected contract
const isLoading = ref<boolean>(false); // Indicates if the data is being loaded

// Table headers for displaying the list of contracts
const headers = ref([
  { key: 'contract_name', label: 'CONTRACT NAME', width: 300 },
  { key: 'valid_from', label: 'VALID FROM', width: 250, date: true },
  { label: 'SELECTED', width: 200, date: true }
]);

// Close the modal and reset any necessary state
const closeModal = (): void => {
  emit('close'); // Emit the 'close' event to inform the parent component
};

const restartInfo = (): void => {
  emit('restart');
};

// Handle row click and update the selected contract
const handleRowClick = (row: Contract): void => {
  insurerContractSelected.value = row; // Update the selected contract when a row is clicked
};

// Disable the "Save" button if no contract is selected
const isButtonDisabled = computed((): boolean => {
  return insurerContractSelected.value === null; // Disable if no contract is selected
});

// Fetch contracts based on the insurerId
const listContracts = async (insurerId: number | null): Promise<void> => {
  if (insurerId === null) return; // Exit early if insurerId is null

  isLoading.value = true; // Set loading state to true while fetching data
  const oapi = new OpenAPIJSON(); // Create an instance of the OpenAPIJSON class
  try {
    const request = await oapi.searchContracts(insurerId); // Call the API to get contracts
    insurerData.value = request.items; // Store the fetched contracts in insurerData
  } catch (error) {
    // If there's an error, display a toast notification
    toast.value?.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
  } finally {
    isLoading.value = false; // Reset loading state
  }
};

// Load contracts on mount if insurerId is provided
if (props.insurerId !== null) {
  listContracts(props.insurerId); // Automatically fetch contracts when the component is mounted
}

// Simulate saving the selected contract
const changeContract = async (): Promise<void> => {
  if (insurerContractSelected.value && toast.value) {
    const oapi = new OpenAPIJSON();
    const requestData = {
      appointment: {
        type: 'id',
        appointment_id: props.appointmentId
      },
      insurer: {
        type: 'id',
        id: insurerContractSelected.value.insurer_id
      },
      contract: {
        type: 'name',
        name: insurerContractSelected.value.contract_name
      }
    };

    try {
      await oapi.contractInvoiceUpdate(requestData);
      restartInfo();
    } catch (error) {
      console.error('Contract clone fetch error', error);
      showToast('Error duplicating contract.', 'error');
    }
  }
};
</script>

<template>
  <!-- Modal component to display the contract selection UI -->
  <BaseModal @close-modal="closeModal">
    <!-- Modal header section -->
    <template #header>
      <h2 class="text-2xl font-semibold text-medosync-violet-highlight mb-8">
        Select another {{ insurerName }} contract for App.ID {{ appId }}
      </h2>
    </template>

    <!-- Modal body section -->
    <template #body>
      <div>
        <!-- DashboardContracts component to display the list of contracts in a table -->
        <DashboardContracts
          :headers="headers"
          :data="insurerData"
          @row-select="handleRowClick"
          class="mb-8"
        ></DashboardContracts>
      </div>

      <!-- Buttons Section -->
      <div class="flex justify-between items-center">
        <!-- Cancel button to close the modal -->
        <button
          type="button"
          class="bg-[#CBD5E0] shadow rounded-full py-3 px-8 flex justify-center items-center"
          @click="closeModal"
        >
          Cancel
        </button>

        <!-- Save button to save the selected contract -->
        <button
          type="button"
          class="bg-medosync-blue shadow rounded-full py-3 px-8 flex justify-center items-center"
          @click="changeContract"
          :disabled="isButtonDisabled"
        >
          Save Contract for App.ID {{ appId }}
        </button>
      </div>
    </template>
  </BaseModal>

  <!-- Display loader if data is being fetched -->
  <SpinnerLoader v-if="isLoading" />
  <!-- Toast component to show success/error notifications -->
  <ToastComponent ref="toast" />
</template>

<style scoped>
/* Style to remove spin buttons for number inputs in webkit and Firefox */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
