<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick, watch } from 'vue';
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

const emit = defineEmits(['update:dateFilter'], ['open']);

const props = defineProps({
  initialDateFilter: {
    type: Object,
    default: () => ({
      type: null,
      start: null,
      end: null
    })
  }
});

const inputRef = ref(null);
const dropdownRef = ref(null);
const datePickerRef = ref(null);

// State management for dropdown and date selection
const showDropdown = ref(false);
const dropdownPosition = ref({ top: 0, left: 0 });
const selectedType = ref(props.initialDateFilter.type === 'discharged' ? 'discharge' : 'admission');
const selectedMode = ref('custom');
const calendarDate = ref(new Date());

// Initialize selectedRange from props
const selectedRange = ref({
  start: props.initialDateFilter.start ? new Date(props.initialDateFilter.start) : null,
  end: props.initialDateFilter.end ? new Date(props.initialDateFilter.end) : null
});

// Initialize tempRange
const tempRange = ref({
  start: selectedRange.value.start ? new Date(selectedRange.value.start) : null,
  end: selectedRange.value.end ? new Date(selectedRange.value.end) : null
});

// Date type options for admission/discharge selection
const dateTypes = [
  { id: 'admission', label: 'Admission date' },
  { id: 'discharge', label: 'Discharge date' }
];

// Date mode options for different date range selections
const dateModes = [
  { id: 'custom', label: 'Custom' },
  { id: 'today', label: 'Today' },
  { id: 'week', label: 'Week to date' },
  { id: 'month', label: 'Month to date' },
  { id: 'year', label: 'Year to date' }
];

// Watch to synchronise the calendar when the dropdown opens
watch(showDropdown, newValue => {
  if (newValue && selectedRange.value.start) {
    nextTick(() => {
      calendarDate.value = new Date(selectedRange.value.start);
      tempRange.value = {
        start: new Date(selectedRange.value.start),
        end: selectedRange.value.end ? new Date(selectedRange.value.end) : null
      };
      if (datePickerRef.value) {
        const date = new Date(selectedRange.value.start);
        datePickerRef.value.move({
          month: date.getMonth() + 1,
          year: date.getFullYear()
        });
      }
    });
  }
});

// Update dropdown position based on input field location
const updateDropdownPosition = () => {
  if (!inputRef.value || !dropdownRef.value) return;

  const inputRect = inputRef.value.getBoundingClientRect();

  dropdownPosition.value = {
    top: `${inputRect.bottom + 8}px`,
    left: `${inputRect.left}px`
  };
};

// Handle clicks outside the date picker component
const handleClickOutside = event => {
  if (
    dropdownRef.value &&
    !dropdownRef.value.contains(event.target) &&
    inputRef.value &&
    !inputRef.value.contains(event.target)
  ) {
    showDropdown.value = false;

    // Apply changes when clicking outside
    if (tempRange.value.start) {
      selectedRange.value = {
        start: new Date(tempRange.value.start),
        end: tempRange.value.end ? new Date(tempRange.value.end) : null
      };

      emit('update:dateFilter', {
        type: selectedType.value === 'admission' ? 'admitted' : 'discharged',
        start: formatDateToAPI(tempRange.value.start),
        end: formatDateToAPI(tempRange.value.end)
      });
    } else {
      selectedRange.value = {
        start: null,
        end: null
      };

      emit('update:dateFilter', {
        type: selectedType.value === 'admission' ? 'admitted' : 'discharged',
        start: null,
        end: null
      });
    }
  }
};

// Event listeners setup and cleanup
onMounted(() => {
  window.addEventListener('resize', updateDropdownPosition);
  window.addEventListener('scroll', updateDropdownPosition);
  document.addEventListener('mousedown', handleClickOutside);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateDropdownPosition);
  window.removeEventListener('scroll', updateDropdownPosition);
  document.removeEventListener('mousedown', handleClickOutside);
});

// Handle dropdown open event
const handleDropdownOpen = () => {
  showDropdown.value = true;
  emit('open');
  nextTick(() => {
    updateDropdownPosition();

    if (selectedRange.value.start) {
      tempRange.value = {
        start: new Date(selectedRange.value.start),
        end: selectedRange.value.end ? new Date(selectedRange.value.end) : null
      };
    }
  });
};

// Format the date range for display
const formattedDateRange = computed(() => {
  if (!selectedRange.value.start) return '';

  const formatDate = date => {
    if (!date) return '';
    return date
      .toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })
      .split('/')
      .join('.');
  };

  const start = formatDate(selectedRange.value.start);
  const end = formatDate(selectedRange.value.end);

  return end ? `${start}-${end}` : start;
});

// Format date for API requests
const formatDateToAPI = date => {
  if (!date) return null;
  // Use UTC methods to prevent timezone issues
  return `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(
    date.getUTCDate()
  ).padStart(2, '0')}`;
};

// Handle date mode changes
const handleModeChange = mode => {
  selectedMode.value = mode;
  const now = new Date();

  let dates = {
    start: null,
    end: null,
    viewDate: null
  };

  switch (mode) {
    case 'today':
      dates = {
        start: new Date(now),
        end: new Date(now),
        viewDate: new Date(now)
      };
      break;
    case 'week':
      const startOfWeek = new Date(now);
      startOfWeek.setDate(now.getDate() - now.getDay());
      dates = {
        start: new Date(startOfWeek),
        end: new Date(now),
        viewDate: new Date(startOfWeek)
      };
      break;
    case 'month':
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      dates = {
        start: new Date(startOfMonth),
        end: new Date(now),
        viewDate: new Date(startOfMonth)
      };
      break;
    case 'year':
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      dates = {
        start: new Date(startOfYear),
        end: new Date(now),
        viewDate: new Date(startOfYear)
      };
      break;
    case 'custom':
      if (selectedRange.value.start) {
        dates = {
          start: new Date(selectedRange.value.start),
          end: selectedRange.value.end ? new Date(selectedRange.value.end) : null,
          viewDate: new Date(selectedRange.value.start)
        };
      }
      break;
  }

  calendarDate.value = dates.viewDate || new Date();
  tempRange.value = {
    start: dates.start,
    end: dates.end
  };
  selectedRange.value = {
    start: dates.start,
    end: dates.end
  };

  nextTick(() => {
    if (datePickerRef.value && dates.viewDate) {
      const month = dates.viewDate.getMonth() + 1;
      const year = dates.viewDate.getFullYear();
      datePickerRef.value.move({ month, year });
    }
  });

  // Emit the date filter update
  emit('update:dateFilter', {
    type: selectedType.value === 'admission' ? 'admitted' : 'discharged',
    start: formatDateToAPI(dates.start),
    end: formatDateToAPI(dates.end)
  });
};

// Reset all selections
const handleReset = () => {
  tempRange.value = {
    start: null,
    end: null
  };
  selectedMode.value = 'custom';
  selectedRange.value = {
    start: null,
    end: null
  };
  emit('update:dateFilter', {
    type: selectedType.value === 'admission' ? 'admitted' : 'discharged',
    start: null,
    end: null
  });
};

// Handle individual date selection
const handleDateSelect = day => {
  if (selectedMode.value !== 'custom') {
    selectedMode.value = 'custom';
  }
};

// Watch for date type changes
watch(selectedType, () => {
  if (selectedRange.value.start) {
    emit('update:dateFilter', {
      type: selectedType.value === 'admission' ? 'admitted' : 'discharged',
      start: formatDateToAPI(selectedRange.value.start),
      end: formatDateToAPI(selectedRange.value.end)
    });
  }
});

// Watch for changes in tempRange
watch(
  tempRange,
  newRange => {
    if (selectedMode.value === 'custom' && newRange.start) {
      emit('update:dateFilter', {
        type: selectedType.value === 'admission' ? 'admitted' : 'discharged',
        start: formatDateToAPI(newRange.start),
        end: formatDateToAPI(newRange.end)
      });
    }
  },
  { deep: true }
);

// Expose handleReset method to parent component
defineExpose({ handleReset });
</script>

<template>
  <div class="relative" ref="inputRef" data-test-id="date-picker-container">
    <!-- Input Field -->
    <div class="flex items-center gap-2">
      <span class="text-gray-900 font-normal" data-test-id="date-label">Date</span>
      <div @click="handleDropdownOpen" class="relative flex-1 cursor-pointer" data-test-id="date-input-wrapper">
        <div class="flex items-center rounded-md border-2 border-blue-400 bg-white">
          <input
            type="text"
            :value="formattedDateRange"
            readonly
            placeholder="dd.mm.yyyy-dd.mm.yyyy"
            class="w-full px-3 py-2 outline-none cursor-pointer placeholder-gray-400 text-gray-600 border-0"
            data-test-id="date-input"
          />
          <div class="pr-3" data-test-id="calendar-icon">
            <svg
              class="w-5 h-5 text-gray-600"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
              <line x1="16" y1="2" x2="16" y2="6" />
              <line x1="8" y1="2" x2="8" y2="6" />
              <line x1="3" y1="10" x2="21" y2="10" />
            </svg>
          </div>
        </div>
        <div class="absolute inset-0 -z-10 rounded-md shadow-[0_0_0_4px_rgba(96,165,250,0.2)]"></div>
      </div>
    </div>

    <!-- Dropdown -->
    <div
      v-if="showDropdown"
      ref="dropdownRef"
      class="fixed bg-white rounded-lg shadow-xl z-50"
      :style="{ top: dropdownPosition.top, left: dropdownPosition.left }"
      data-test-id="date-picker-dropdown"
    >
      <div class="border border-gray-200">
        <div class="p-6">
          <!-- Date Type Header -->
          <p class="text-gray-600 mb-4" data-test-id="date-type-header">Date type</p>
          <div class="flex gap-4">
            <label
              v-for="type in dateTypes"
              :key="type.id"
              class="flex items-center gap-2"
              :data-test-id="'date-type-' + type.id"
            >
              <input
                type="radio"
                :value="type.id"
                v-model="selectedType"
                class="radio-input appearance-none h-10 w-10 rounded-full border-2 border-gray-300 cursor-pointer checked:bg-white"
                :data-test-id="'date-type-radio-' + type.id"
              />
              <span class="whitespace-nowrap">{{ type.label }}</span>
            </label>
          </div>

          <div class="h-px bg-black w-full mt-4"></div>

          <div class="flex gap-8 mt-8">
            <!-- Left Side - Mode Controls -->
            <div class="w-64" data-test-id="date-modes-container">
              <div class="space-y-2">
                <label
                  v-for="mode in dateModes"
                  :key="mode.id"
                  class="flex items-center gap-2"
                  :data-test-id="'date-mode-' + mode.id"
                >
                  <div class="relative w-10 h-10">
                    <input
                      type="radio"
                      :value="mode.id"
                      v-model="selectedMode"
                      @change="handleModeChange(mode.id)"
                      class="sr-only w-10 h-10"
                      :data-test-id="'date-mode-radio-' + mode.id"
                    />
                    <div
                      :class="[
                        'w-10 h-10 rounded border-2',
                        selectedMode === mode.id ? 'border-blue-400 bg-blue-400' : 'border-black'
                      ]"
                    >
                      <svg
                        v-if="selectedMode === mode.id"
                        class="w-6 h-6 text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                  </div>
                  <span class="whitespace-nowrap">{{ mode.label }}</span>
                </label>
              </div>

              <div class="mt-4">
                <button @click="handleReset" class="text-blue-600 hover:underline" data-test-id="reset-button">
                  Reset
                </button>
              </div>
            </div>

            <!-- Right Side - DatePicker -->
            <div class="flex-1" data-test-id="calendar-container">
              <DatePicker
                ref="datePickerRef"
                v-model="tempRange"
                :initial-page="{ month: calendarDate.getMonth() + 1, year: calendarDate.getFullYear() }"
                is-range
                :columns="2"
                mode="date"
                locale="en-US"
                :masks="{
                  title: 'MMM YYYY'
                }"
                class="custom-calendar"
                :attributes="[
                  {
                    key: 'today',
                    dot: true,
                    dates: new Date()
                  }
                ]"
                @dayclick="handleDateSelect"
                data-test-id="v-calendar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
/* Custom radio input styling */
.radio-input {
  position: relative;
}

.radio-input:checked {
  background-color: white;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #00a7e1;
  border-color: transparent;
}

.radio-input:checked::before {
  content: '';
  position: absolute;
  width: 80%;
  height: 80%;
  background-color: #00a7e1;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Calendar custom theme variables */
.custom-calendar {
  --vc-font-family: inherit;
  --vc-text-gray-400: #94a3b8;
  --vc-text-gray-500: #64748b;
  --vc-bg-gray-100: #f1f5f9;
  --vc-bg-gray-200: #e2e8f0;
  --vc-bg-blue-600: #00a7e1;
  --vc-border-radius: 0.375rem;
  --vc-nav-hover-bg: var(--vc-bg-gray-100);
  --vc-today-bg: var(--vc-bg-gray-100);
}

/* Calendar container styling */
.custom-calendar .vc-container {
  border: none;
  background: transparent;
}

/* Calendar header styling */
.custom-calendar .vc-header {
  padding: 10px 16px;
}

/* Calendar weeks styling */
.custom-calendar .vc-weeks {
  padding: 0 16px 16px;
}

/* Calendar day cell styling */
.custom-calendar .vc-day {
  height: 32px;
  padding: 0 4px;
}

/* Calendar day content styling */
.custom-calendar .vc-day-content {
  height: 28px;
  width: 28px;
}

/* Calendar day hover effect */
.custom-calendar .vc-day-content:hover {
  background-color: var(--vc-bg-gray-100);
}

/* Calendar highlighted day styling */
.custom-calendar .vc-highlight {
  background-color: var(--vc-bg-blue-600) !important;
  color: white;
}

/* Calendar range start and end styling */
.custom-calendar .vc-day.is-end,
.custom-calendar .vc-day.is-start {
  background-color: var(--vc-bg-blue-600) !important;
}

/* Calendar range styling */
.custom-calendar .vc-day.is-in-range {
  background-color: rgba(37, 99, 235, 0.1) !important;
}
</style>
