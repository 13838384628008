import { userStore } from '@/store/user';
import { hospitalConfigStore } from '@/store/config';
import { createRouter, createWebHistory, RouterView } from 'vue-router';

// auth
import Login from '@/views/auth/Login.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';

// main
import ClaimsList from '@/views/dashboard/Claims-list.vue';
import ConsultantClaimDetailsPage from '@/views/claims/details/ConsultantClaimDetailsPage.vue';
//import ClaimsListBackup from '@/views/dashboard/Backup-Claims-list.vue';
import CompositeClaimsListCcp from '@/views/dashboard/CompositeClaimsListCcp.vue';

// Admission
import AdmissionDetailsPage from '@/views/admission/AdmissionDetailsPage.vue';
import AdmissionPage from '@/views/admission/AdmissionPage.vue';
// user management
import SettingsMainLayout from '@/views/user-management/SettingsMainLayout.vue';
import UserDashboard from '@/views/user-management/Dashboard.vue';
import DoctorsDashboard from '@/views/user-management/Doctors.vue';
import PatientsDashboard from '@/views/user-management/Patients.vue';
import HospitalsDashboard from '@/views/user-management/Hospitals.vue';
import GroupsDashboard from '@/views/user-management/Groups.vue';
import InsuranceContracts from '@/views/user-management/InsuranceContracts.vue';

// actions
import CreateUser from '@/views/user-management/CreateUser.vue';
import CreateGroup from '@/views/user-management/CreateGroup.vue';
import EditDoctor from '@/views/user-management/EditDoctor.vue';
import EditGroup from '@/views/user-management/EditGroup.vue';

// finance
import Finance from '@/views/finance/Finance.vue';
import ConsultantFiles from '@/views/finance/ConsultantFiles.vue';
import ReportingDashboard from '@/views/finance/ReportingDashboard.vue';
import ReportingBars from '@/views/finance/ReportingBars.vue';

// reporting
import InsurerSummary from '@/views/finance/reporting/InsurerSummary.vue';
import Submitted from '@/views/finance/reporting/Submitted.vue';
import ClaimsSignoff from '@/views/finance/reporting/ClaimsSignoff.vue';
import Aged from '@/views/finance/reporting/Aged.vue';
import Outstanding from '@/views/finance/reporting/Outstanding.vue';
import OutstandingInsurer from '@/views/finance/reporting/OutstandingInsurer.vue';
import OutstandingProcedure from '@/views/finance/reporting/OutstandingProcedure.vue';
import OutstandingPended from '@/views/finance/reporting/OutstandingPended.vue';
import OutstandingRejected from '@/views/finance/reporting/OutstandingRejected.vue';
import PaymentSummary from '@/views/finance/reporting/PaymentSummary.vue';

// notifications
import MainNotificationCentre from '@/views/notifications/MainNotificationCentre.vue';
import MainNotificationCentreCcp from '@/views/notifications/MainNotificationCentreCcp.vue';

// settings
import dashboardSettings from '@/views/settings/Dashboard-settings.vue';
import tagsManagement from '@/views/settings/Tags-settings.vue';
import menuManagement from '@/views/settings/Menu-settings.vue';
import CreateTag from '@/views/settings/CreateTag.vue';

//profile
import EditProfile from '@/views/profile/EditProfile.vue';
// components
import SideNav from '@/components/navigation/SideNav.vue';
//tests
import TestLayout from '@/views/tests/TestLayout.vue';
//import CompositeClaimsList from '@/views/dashboard/CompositeClaimsList.vue';
import CompositeClaimDetailsPage from '@/views/claims/details/CompositeClaimDetailsPage.vue';
import NotAuthorized from '@/views/NotAuthorized.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  // navigator
  {
    path: '/navigator',
    name: 'Navigator',
    children: [
      // dashboard
      {
        path: '/navigator/claims',
        name: 'Claims-list',
        component: ClaimsList,
        props: true
      },
      {
        path: '/navigator/compositeclaims',
        name: 'Claims-list-ccp',
        component: CompositeClaimsListCcp,
        props: true
      },
      // {
      //   path: '/navigator/claims/:filter/:tag',
      //   name: 'Claims-list',
      //   component: ClaimsListBackup,
      //   props: true
      // },
      // details
      {
        path: '/navigator/consclaim/details/:id',
        name: 'ConsultantClaimsDetails',
        component: ConsultantClaimDetailsPage
      },
      // {
      //   path: '/navigator/compositeclaims/:filter/:tag',
      //   name: 'CompositeClaimsList',
      //   component: CompositeClaimsList,
      //   props: true
      // },
      {
        path: '/navigator/compositeclaims/details/:id',
        name: 'CompositeClaimsDetails',
        component: CompositeClaimDetailsPage
      },

      //payments
      {
        path: '/navigator/finance',
        name: 'finance',
        component: RouterView,
        redirect: '/navigator/finance/payment-files',
        children: [
          {
            path: '/navigator/finance/payment-files',
            name: 'paymentFiles',
            component: Finance
          },
          {
            path: '/navigator/finance/files',
            name: 'consultantFiles',
            component: ConsultantFiles
          }
        ]
      },
      // reporting
      {
        path: '/navigator/finance/reporting',
        name: 'finance-reporting',
        component: RouterView,
        redirect: '/navigator/finance/reporting/dashboard',
        children: [
          {
            path: '/navigator/finance/reporting/dashboard',
            name: 'reportingDashboard',
            component: ReportingDashboard
          },
          {
            path: '/navigator/finance/reporting/submitted',
            name: 'submitted',
            component: Submitted
          },
          {
            path: '/navigator/finance/reporting/claims-signoff',
            name: 'claimsSignoff',
            component: ClaimsSignoff
          },
          {
            path: '/navigator/finance/reporting/aged',
            name: 'aged',
            component: Aged
          },
          {
            path: '/navigator/finance/reporting/outstanding',
            name: 'outstanding',
            component: Outstanding
          },
          {
            path: '/navigator/finance/reporting/outstanding-insurer',
            name: 'outstandingInsurer',
            component: OutstandingInsurer
          },
          {
            path: '/navigator/finance/reporting/outstanding-procedure',
            name: 'outstandingProcedure',
            component: OutstandingProcedure
          },
          {
            path: '/navigator/finance/reporting/outstanding-pended',
            name: 'outstandingPended',
            component: OutstandingPended
          },
          {
            path: '/navigator/finance/reporting/outstanding-rejected',
            name: 'outstandingRejected',
            component: OutstandingRejected
          },
          {
            path: '/navigator/finance/reporting/payment-summary',
            name: 'payment-summary',
            component: PaymentSummary
          },
          {
            path: '/navigator/finance/reporting/bars',
            name: 'reportingBars',
            component: ReportingBars
          },
          {
            path: '/navigator/finance/reporting/insurer-summary',
            name: 'insurerSummary',
            component: InsurerSummary
          }
        ]
      },
      //notifications
      // {
      //   path: '/navigator/notifications/list',
      //   name: 'notifications',
      //   component: MainNotificationCentre,
      //   children: [
      //     {
      //       path: '/navigator/notifications/list/:id/:hospitalAppointmentId',
      //       name: 'MainNotificationCentre',
      //       component: MainNotificationCentre
      //     }
      //   ]
      // },
      {
        path: '/navigator/notifications/list',
        name: 'notificationsccp',
        component: MainNotificationCentreCcp,
        children: [
          {
            path: '/navigator/notifications/list/:id/:hospitalAppointmentId',
            name: 'MainNotificationCentre',
            component: MainNotificationCentreCcp
          }
        ]
      }
    ]
  },
  // admission
  {
    path: '/access',
    name: 'Access',
    redirect: '/access/admission',
    children: [
      {
        path: '/access/admission/:id?',
        name: 'Admission',
        component: AdmissionPage
      },
      {
        path: '/access/admission/details/:id',
        name: 'AdmissionDetails',
        component: AdmissionDetailsPage
      }
    ]
  },
  // users managment
  {
    path: '/settings',
    name: 'settings',
    component: SettingsMainLayout,
    redirect: '/settings/claims-table',
    children: [
      {
        path: '/settings/claims-table',
        name: 'claimsTable',
        component: dashboardSettings
      },
      {
        path: '/settings/tags-management',
        name: 'tagsManagement',
        component: tagsManagement
      },
      {
        path: '/settings/menu-management',
        name: 'menuManagement',
        component: menuManagement
      },
      {
        path: '/settings/dashboard',
        name: 'User-dashboard',
        component: UserDashboard
      },
      {
        path: '/settings/manage-users',
        name: 'Doctors-dashboard',
        component: DoctorsDashboard
      },
      {
        path: '/settings/patients',
        name: 'Patients-dashboard',
        component: PatientsDashboard
      },
      {
        path: '/settings/hospitals',
        name: 'Hospitals-dashboard',
        component: HospitalsDashboard
      },
      {
        path: '/settings/create-user',
        name: 'Create-user',
        component: CreateUser
      },
      {
        path: '/settings/edit-doctor/:id',
        name: 'Edit-doctor',
        component: EditDoctor
      },
      {
        path: '/settings/groups',
        name: 'Groups',
        component: GroupsDashboard
      },
      {
        path: '/settings/create-group',
        name: 'Create-group',
        component: CreateGroup
      },
      {
        path: '/settings/edit-group/:id',
        name: 'Edit-group',
        component: EditGroup
      },
      {
        path: '/settings/contracts',
        name: 'InsuranceContracts',
        component: InsuranceContracts
      },
      {
        path: '/settings/create-tag',
        name: 'CreateTag',
        component: CreateTag
      }
    ]
  },
  //profile
  {
    path: '/profile/edit',
    name: 'editProfile',
    component: EditProfile
  },
  // nav
  {
    path: '/sidenav',
    name: 'SideNav',
    component: SideNav
  },
  // test layout
  {
    path: '/test-layout',
    name: 'test-layout',
    component: TestLayout
  },
  {
    path: '/not-authorized',
    name: 'NotAuthorized',
    component: NotAuthorized
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

// Create a promise to track the initial config loading
let configLoadPromise = null;

router.beforeEach(async (to, from, next) => {
  // Public routes don't need authentication
  const publicRoutes = ['Login', 'ResetPassword', 'NotAuthorized', 'SideNav'];
  if (publicRoutes.includes(to.name)) {
    next();
    return;
  }

  const token = localStorage.getItem('_token');
  const tokenExpiration = localStorage.getItem('tokenExpiration');

  // Check authentication
  if (!token || !tokenExpiration) {
    next({ name: 'Login' });
    return;
  }

  // Check token expiration
  const expiration = new Date(tokenExpiration);
  const now = new Date();
  if (now.getTime() > expiration) {
    sessionStorage.clear();
    localStorage.removeItem('_token');
    localStorage.removeItem('tokenExpiration');
    next({ name: 'Login' });
    return;
  }

  try {
    // Load configs only once per app lifecycle
    if (!configLoadPromise) {
      configLoadPromise = Promise.all([userStore.dispatch('getUserInfo'), hospitalConfigStore.dispatch('getConfig')]);
    }

    // Wait for configs to load
    await configLoadPromise;

    // Check permissions after configs are loaded
    const hasAccess = hospitalConfigStore.getters.has_product_access;
    const hasNavigator = hospitalConfigStore.getters.has_product_navigator;

    // Check if trying to access settings route
    const isSettingsRoute = to.path.startsWith('/settings');

    // Both accesses
    if (hasNavigator && hasAccess) {
      next();
      return;
    }

    // Only Navigator
    if (hasNavigator && !hasAccess) {
      if (to.path.startsWith('/access')) {
        next('/not-authorized');
      } else {
        next();
      }
      return;
    }

    // Only Access - Block both navigator and settings routes
    if (!hasNavigator && hasAccess) {
      if (to.path.startsWith('/navigator') || isSettingsRoute) {
        next('/not-authorized');
      } else {
        next();
      }
      return;
    }

    // No access
    next('/not-authorized');
  } catch (error) {
    console.error('Error loading configurations:', error);
    next({ name: 'Login' });
  }
});

export default router;
