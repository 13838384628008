<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

const props = defineProps({
  closeOnClickOutside: {
    type: Boolean,
    default: false
  },
  hideHeader: {
    type: Boolean,
    default: false
  },
  hideBody: {
    type: Boolean,
    default: false
  },
  hideCloseButton: {
    type: Boolean,
    default: false
  },
  hideExpandButton: {
    type: Boolean,
    default: true
  }
});
const emit = defineEmits(['close-modal']);

const rendered = ref(false);
const shouldShow = ref(false);
const expanded = ref(false);
const modalRef = ref<HTMLElement | null>(null);

const onClickOutside = () => {
  if (props.closeOnClickOutside) onClose();
};
const onClose = () => {
  shouldShow.value = false;
  setTimeout(() => {
    emit('close-modal');
  }, 200);
};
const onExpand = () => {
  expanded.value = !expanded.value;
};
const handleClickOutside = (event: MouseEvent) => {
  if (!modalRef.value) return;
  const isClickInside = modalRef.value.contains(event.target as Node);

  if (!isClickInside) {
    onClickOutside();
  }
};

onMounted(() => {
  setTimeout(() => {
    rendered.value = true;
    setTimeout(() => {
      shouldShow.value = true;
    }, 0);
  }, 0);

  document.addEventListener('pointerdown', handleClickOutside);
});
onUnmounted(() => {
  document.removeEventListener('pointerdown', handleClickOutside);
});
</script>

<template>
  <transition v-if="rendered" name="modal">
    <div v-show="shouldShow" class="modal-mask">
      <div class="modal-wrapper">
        <div
          ref="modalRef"
          class="modal-container"
          :class="[expanded ? 'modal-container-width-expanded' : 'modal-container-width']"
        >
          <div class="relative">
            <i v-if="!hideCloseButton" @click="onClose" class="fas fa-times close-button"> </i>
            <i v-if="!hideExpandButton" @click="onExpand" class="fas fa-expand-alt expand-button"> </i>
          </div>
          <div v-if="!hideHeader" class="modal-header">
            <slot name="header" />
          </div>

          <div v-if="!hideBody" class="modal-body">
            <slot name="body" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: -webkit-fill-available;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 100%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  max-height: 98vh;
  overflow: auto;
  position: relative;
}

.modal-container-width {
  width: 50%;
}

.modal-container-width-expanded {
  width: 90%;
}

.modal-header {
  border: none;
  position: relative;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.close-button {
  z-index: 9000;
  margin-top: 2px;
  cursor: pointer;
  right: 0;
  position: absolute;
  color: rgb(83, 83, 83);
  font-size: 24px;
}

.expand-button {
  z-index: 9000;
  margin-top: 2px;
  cursor: pointer;
  right: 35px;
  position: absolute;
  color: rgb(83, 83, 83);
  font-size: 20px;
}

@media only screen and (max-width: 1024px) {
  .modal-container {
    width: 90%;
  }
}
</style>
