<script setup>
import { ref } from 'vue';
import { OpenAPIJSON } from 'api/openapi';
import NotificationSearchMrn from '@/components/notifications/NotificationSearchMrn.vue';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import NotificationsMessage from '@/components/notifications/NotificationsMessage.vue';
import FooterModalNotification from '@/components/notifications/FooterModalNotification.vue';
import AddClaimNotification from './AddClaimNotification.vue';

const props = defineProps({
  messages: {
    type: Object,
    required: true
  },
  allMessages: {
    type: Array,
    required: true
  }
});
const emit = defineEmits(['modal-module', 'show-toast-message']);

const openApi = new OpenAPIJSON();
const results = ref([]);
const items = ref([]);
const isLoading = ref(false);
const selectedItemIndex = ref(null);
const searchQuery = ref(false);
const sortBy = ref('desc:mrn');
const editMode = ref(false);
const newClaim = ref(false);
const restValue = ref(false);
const restClaimId = ref(false);
const buttonAction = ref('Assign message to this claim');
const createOpenAPIJSON = () => new OpenAPIJSON();
const getPaginationConfig = () => ({
  start_idx: 1,
  end_idx: 100,
  page_size: null
});

const apiSearch = async () => {
  try {
    isLoading.value = true;
    const oapi = createOpenAPIJSON();
    const pagination = getPaginationConfig();

    const { items: data } = await oapi.searchClaims(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      props.messages.consultantId,
      null,
      null,
      null,
      null,
      sortBy.value,
      pagination
    );
    items.value = data;
  } catch (error) {
    emit('show-toast-message', { message: error, icon: 'fa-solid fa-triangle-exclamation', type: 'error' });
  } finally {
    isLoading.value = false;
  }
};

const handleCancel = () => {
  restValue.value = 'cancel';
  newClaim.value = false;
  editMode.value = false;
  searchQuery.value = '';
  emit('modal-module-off');
};

const handleError = () => {
  restValue.value = 'errorCreate';
};

const handleCreate = async () => {
  if (!newClaim.value) {
    await addMessageAssoc();
    restValue.value = 'finallyCreate';
    newClaim.value = false;
    editMode.value = false;
    searchQuery.value = '';
    emit('modal-module-off');
  } else {
    restValue.value = 'claimCreate';
  }
};

const finallyCreate = async (newClaimConsultantId = null) => {
  await addMessageAssoc(newClaimConsultantId);
  restValue.value = 'finallyCreate';
  newClaim.value = false;
  editMode.value = false;
  searchQuery.value = '';
  emit('modal-module-off');
};

const addNewClaim = () => {
  buttonAction.value = 'Create claim and assign message';
  newClaim.value = true;
  editMode.value = true;
  searchQuery.value = '';
};

const handleSearchResults = searchResults => {
  searchQuery.value = searchResults.length === 0;
  items.value = searchResults.items;
  results.value = searchResults;
  selectedItemIndex.value = null;
  editMode.value = false;
};

const listAgain = () => {
  searchQuery.value = false;
  apiSearch();
};

const close = () => {
  emit('modal-module-off');
};

const handleCheckboxChange = index => {
  selectedItemIndex.value = selectedItemIndex.value === index ? null : index;
};

const handleRowClick = (index, item) => {
  handleCheckboxChange(index);
  editMode.value = true;
  restClaimId.value = item.claim_consultant_id;
};

const addMessageAssoc = async (claimConsultantId = restClaimId.value) => {
  try {
    isLoading.value = true;
    await openApi.message_add_assoc(props.messages.message.id, claimConsultantId);
    updateAddedAttach(props.messages.message.id, claimConsultantId);
    emit('show-toast-message', {
      message: 'The message was assigned to selected claim successfully.',
      icon: 'fas fa-check',
      type: 'success'
    });
  } catch (error) {
    emit('show-toast-message', { message: error, icon: 'fa-solid fa-triangle-exclamation', type: 'error' });
  } finally {
    isLoading.value = false;
  }
};

const updateAddedAttach = (messageID, targetIds) => {
  const shownMessages = props.allMessages;
  const messageIndex = shownMessages.findIndex(message => message.message.id === messageID);
  if (messageIndex === -1) {
    return;
  }
  const newItem = {
    id: targetIds,
    type: 'id',
    assoc_type: 'consultantclaim'
  };
  shownMessages[messageIndex].assoc.push(newItem);
  emit('update-messages', shownMessages);
};

apiSearch();
</script>
<template>
  <div @click.self="close" class="fixed inset-0 bg-medosync-white z-40 border-2 border-medosync-black overflow-auto">
    <div @click.stop class="absolute top-0 right-0 p-5">
      <button
        @click.stop="close"
        class="text-medosync-black text-3xl"
        data-test-id="notification-message-overlay-close-button"
      >
        &times;
      </button>
    </div>

    <div @click.stop class="h-screen flex flex-col p-12" :class="{ 'pb-20': editMode === true }">
      <h2 class="text-4xl font-bold text-medosync-violet-highlight mb-10">Assign this Message to a Claim</h2>
      <NotificationSearchMrn
        @searchResults="handleSearchResults"
        @listAgain="listAgain()"
        class="mb-4"
        v-if="!newClaim"
        :consultantId="props.messages.consultantId"
      />
      <div class="flex grow min-h-0 justify-between items-center gap-x-4">
        <div class="h-full overflow-y-auto pe-3 w-1/2">
          <div v-if="searchQuery" class="w-full">
            <div class="text-center">
              <p class="text-xl font-semibold">No claim found for this search.</p>
            </div>
            <div class="text-center">
              <p class="text-base">Please check your search input and try again</p>
            </div>
            <div class="text-center">
              <p class="text-base">or</p>
            </div>
            <div class="text-center mt-6">
              <button
                class="rounded-full shadow-md bg-medosync-blue text-xs w-[130px] h-[50px] px-2 py-1"
                @click="addNewClaim"
                data-test-id="notification-message-overlay-create-claim-button"
              >
                Create claim
              </button>
            </div>
          </div>
          <AddClaimNotification
            v-if="newClaim"
            :saveForm="restValue"
            @update-error="handleError"
            @save="finallyCreate"
          />
          <table v-if="!searchQuery && !newClaim" class="w-full">
            <thead class="bg-gray-300">
              <tr>
                <th class="px-4 py-2"></th>
                <th class="px-4 py-2 text-medosync-black text-center">MRN</th>
                <th class="px-4 py-2 text-medosync-black text-center">Patient Name</th>
                <th class="px-4 py-2 text-medosync-black text-center">App. ID</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="index"
                :class="[
                  index % 2 === 0 ? '' : 'bg-medosync-grey',
                  selectedItemIndex === index ? 'border-2 border-medosync-blue' : '',
                  'hover:bg-medosync-blue transition-colors duration-150'
                ]"
                @click.prevent="handleRowClick(index, item)"
                :data-test-id="`notification-message-overlay-claim-row-${index}`"
              >
                <td class="px-4 py-2 text-center">
                  <label :for="'checkbox-' + index" class="block cursor-pointer relative">
                    <input
                      :id="'checkbox-' + index"
                      type="checkbox"
                      class="hidden"
                      @change="() => handleCheckboxChange(index)"
                      :checked="selectedItemIndex === index"
                    />
                    <div
                      :class="[
                        'w-6 h-6 rounded-full border-2 m-2 flex justify-center items-center',
                        selectedItemIndex === index ? 'border-blue-500' : 'border-medosync-black'
                      ]"
                    >
                      <div v-if="selectedItemIndex === index" class="w-4 h-4 bg-blue-500 rounded-full"></div>
                    </div>
                  </label>
                </td>
                <td class="px-4 py-2 text-medosync-black text-center">{{ item.patient.mrn }}</td>
                <td class="px-4 py-2 text-medosync-black text-center">
                  {{ item.patient.first_name }} {{ item.patient.last_name }}
                </td>
                <td class="px-4 py-2 text-medosync-black text-center">{{ item.hospital_appointment_id }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <NotificationsMessage v-if="messages" :message="messages" :show-close-button="false" class="w-1/2" />
      </div>
      <FooterModalNotification
        v-if="editMode"
        @cancel="handleCancel"
        @create="handleCreate"
        :buttonAction="buttonAction"
      />
    </div>
    <SpinnerLoader v-if="isLoading" />
  </div>
</template>
