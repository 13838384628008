<!-- MoreFiltersDropdown.vue -->
<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { OpenAPIJSON } from 'api/openapi';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';

const emit = defineEmits(['applied-filters']);

// State management
const isOpen = ref(false);
const isLoading = ref(false);
const consultants = ref([]);
const selectedConsultants = ref([]);
const selectedStatuses = ref(['all']);
const searchQuery = ref('');
const itemsPerColumn = ref(10);
const showMoreVisible = ref(false);

// Define available status options
const statuses = [
  { id: 'all', label: 'All messages' },
  { id: 'unread', label: 'Unread' },
  { id: 'read', label: 'Read' },
  { id: 'assigned', label: 'Assigned' },
  { id: 'not_assigned', label: 'Not assigned' }
];

// Compute active filters count
const activeFiltersCount = computed(() => {
  let count = 0;

  // Count status filters
  if (!selectedStatuses.value.includes('all') && selectedStatuses.value.length > 0) {
    count++;
  }

  // Count consultant filters
  if (selectedConsultants.value.length > 0) {
    count++;
  }

  return count > 0 ? `(${count})` : '';
});

// Filter consultants based on search query
const filteredConsultants = computed(() => {
  if (!searchQuery.value.trim()) {
    return consultants.value;
  }
  return consultants.value.filter(consultant =>
    consultant.medic.ro.full_name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

// Modified computed properties for 3 columns
const columnConsultants = computed(() => {
  const filtered = filteredConsultants.value;
  const total = filtered.length;
  const columns = [];

  // Calculate items per column (3 columns)
  for (let i = 0; i < 3; i++) {
    const start = i * itemsPerColumn.value;
    const end = start + itemsPerColumn.value;
    columns.push(filtered.slice(start, end));
  }

  // Update show more visibility
  showMoreVisible.value = total > itemsPerColumn.value * 3;

  return columns;
});

// Check if all consultants are selected
const allConsultantsSelected = computed(() => {
  return selectedConsultants.value.length === consultants.value.length;
});

// Loading wrapper utility
const withLoading = async (apiCall, options = {}) => {
  const { loadingDelay = 300, errorMessage, retryCount = 0, timeout = 30000 } = options;

  let attempts = 0;
  const loadingTimeout = setTimeout(() => {
    isLoading.value = true;
  }, loadingDelay);

  try {
    while (attempts <= retryCount) {
      try {
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('Operation timed out')), timeout);
        });

        const resultPromise = apiCall();
        const result = await Promise.race([resultPromise, timeoutPromise]);
        return { data: result, error: null };
      } catch (error) {
        attempts++;
        if (attempts > retryCount) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  } catch (error) {
    console.error('[API Error]:', error);
    return {
      error: true,
      message: errorMessage || 'Something went wrong. Please try again later.'
    };
  } finally {
    clearTimeout(loadingTimeout);
    isLoading.value = false;
  }
};

// Function to handle show more click
const handleShowMore = () => {
  if (itemsPerColumn.value === 10) {
    itemsPerColumn.value = 15;
  } else {
    itemsPerColumn.value += 5;
  }
};

// Check if a status is currently selected
const isStatusSelected = statusId => {
  if (statusId === 'all') {
    return selectedStatuses.value.includes('all');
  }
  return selectedStatuses.value.includes(statusId) || selectedStatuses.value.includes('all');
};

// Toggle status selection
const toggleStatus = statusId => {
  if (statusId === 'all') {
    if (!selectedStatuses.value.includes('all')) {
      selectedStatuses.value = ['all'];
    } else {
      selectedStatuses.value = [];
    }
  } else {
    if (selectedStatuses.value.includes('all')) {
      selectedStatuses.value = statuses
        .filter(status => status.id !== 'all' && status.id !== statusId)
        .map(status => status.id);
    } else {
      if (selectedStatuses.value.includes(statusId)) {
        selectedStatuses.value = selectedStatuses.value.filter(id => id !== statusId);
      } else {
        selectedStatuses.value.push(statusId);
      }

      const allOtherStatuses = statuses.filter(status => status.id !== 'all').map(status => status.id);
      if (allOtherStatuses.every(id => selectedStatuses.value.includes(id))) {
        selectedStatuses.value = ['all'];
      }
    }
  }

  emitChanges();
};

// Toggle all consultants
const toggleAllConsultants = () => {
  if (allConsultantsSelected.value) {
    selectedConsultants.value = [];
  } else {
    selectedConsultants.value = [...consultants.value];
  }

  emitChanges();
};

// Handle consultant click
const handleConsultantClick = consultant => {
  const index = selectedConsultants.value.findIndex(
    c => c.medic.ro.consultant_id === consultant.medic.ro.consultant_id
  );

  if (index === -1) {
    selectedConsultants.value.push(consultant);
  } else {
    selectedConsultants.value.splice(index, 1);
  }

  emitChanges();
};

// API Calls
const fetchMedics = async () => {
  const oapi = new OpenAPIJSON();
  const { data, error } = await withLoading(() => oapi.searchDoctors(null, null, null, true, false), {
    errorMessage: 'Error fetching doctors',
    retryCount: 1
  });

  if (error) return;
  consultants.value = data;
};

// Emit changes
const emitChanges = () => {
  emit('applied-filters', {
    statuses: [...selectedStatuses.value],
    consultants: selectedConsultants.value.map(c => c.medic.ro.consultant_id)
  });
};

const dropdownRef = ref(null);

// Add reset method
const handleReset = () => {
  selectedConsultants.value = [];
  selectedStatuses.value = ['all'];
  searchQuery.value = '';

  // Emit empty filters
  emit('applied-filters', {
    statuses: ['all'],
    consultants: []
  });
};

const handleClickOutside = event => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    isOpen.value = false;
  }
};

// Initialize component
onMounted(async () => {
  await fetchMedics();
  document.addEventListener('click', handleClickOutside);
  emitChanges();
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

// Expose the reset method
defineExpose({ handleReset });
</script>

<!-- Template part only -->
<template>
  <div class="relative" ref="dropdownRef" data-test-id="more-filters-dropdown">
    <!-- Dropdown Button -->
    <button
      @click="isOpen = !isOpen"
      class="relative flex items-center justify-center w-48 px-4 py-2 pr-6 text-sm bg-white border border-medosync-blue rounded-full shadow-sm hover:bg-gray-50 text-medosync-black"
      data-test-id="more-filters-button"
    >
      <span data-test-id="more-filters-count">More Filters {{ activeFiltersCount }}</span>
      <svg
        class="w-4 h-4 absolute right-3.5"
        :class="{ 'rotate-180': isOpen }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        data-test-id="more-filters-arrow"
      >
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
    </button>

    <!-- Dropdown Content -->
    <div
      v-if="isOpen"
      class="absolute left-0 w-[75vw] bg-white border border-medosync-light-grey mt-2 p-6 shadow-lg rounded-lg z-50"
      data-test-id="more-filters-content"
    >
      <SpinnerLoader v-if="isLoading" data-test-id="spinner-loader" />

      <div class="grid grid-cols-4 gap-8">
        <!-- Centralized Search Box -->
        <div class="col-span-4 flex justify-center mb-4">
          <div class="w-1/2">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Search for consultant name"
              class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              data-test-id="consultant-search-input"
            />
          </div>
        </div>

        <!-- Section Titles -->
        <div class="col-span-4 grid grid-cols-4 gap-8 mb-4">
          <div class="col-span-1">
            <h3 class="font-medium" data-test-id="status-section-title">Status</h3>
          </div>
          <div class="col-span-3">
            <h3 class="font-medium" data-test-id="consultant-section-title">Consultant</h3>
          </div>
        </div>

        <!-- Status Section -->
        <div class="col-span-1" data-test-id="status-section">
          <div class="space-y-3">
            <label
              v-for="status in statuses"
              :key="status.id"
              class="flex items-center cursor-pointer hover:bg-gray-50 p-2 rounded whitespace-nowrap"
              :data-test-id="'status-option-' + status.id"
            >
              <div class="medosync-checkbox">
                <input
                  type="checkbox"
                  :checked="isStatusSelected(status.id)"
                  @change="toggleStatus(status.id)"
                  class="sr-only"
                  :data-test-id="'status-checkbox-' + status.id"
                />
                <div class="checkbox-box" :class="{ checked: isStatusSelected(status.id) }">
                  <svg
                    v-if="isStatusSelected(status.id)"
                    class="checkbox-icon checked"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
              <span class="ml-3 text-sm">{{ status.label }}</span>
            </label>
          </div>
        </div>

        <!-- Consultants Section -->
        <div class="col-span-3" data-test-id="consultants-section">
          <!-- Consultants Grid -->
          <div class="grid grid-cols-3 gap-x-16">
            <!-- First Column with All Consultants -->
            <div class="space-y-3" data-test-id="consultants-column-1">
              <!-- All Consultants Checkbox -->
              <div class="flex items-center">
                <div class="medosync-checkbox">
                  <input
                    type="checkbox"
                    :checked="allConsultantsSelected"
                    @change="toggleAllConsultants"
                    data-test-id="all-consultants-checkbox"
                  />
                  <div class="checkbox-box" :class="{ checked: allConsultantsSelected }">
                    <svg
                      v-if="allConsultantsSelected"
                      class="checkbox-icon checked"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                </div>
                <span class="ml-3 text-sm whitespace-nowrap">All Consultants</span>
              </div>

              <!-- First Column Consultants -->
              <div
                v-for="consultant in columnConsultants[0]"
                :key="consultant.medic.ro.consultant_id"
                class="flex items-center"
                :data-test-id="'consultant-item-' + consultant.medic.ro.consultant_id"
              >
                <div class="medosync-checkbox">
                  <input
                    type="checkbox"
                    :checked="
                      selectedConsultants.some(c => c.medic.ro.consultant_id === consultant.medic.ro.consultant_id)
                    "
                    @change="handleConsultantClick(consultant)"
                    :data-test-id="'consultant-checkbox-' + consultant.medic.ro.consultant_id"
                  />
                  <div
                    class="checkbox-box"
                    :class="{
                      checked: selectedConsultants.some(
                        c => c.medic.ro.consultant_id === consultant.medic.ro.consultant_id
                      )
                    }"
                  >
                    <svg
                      v-if="
                        selectedConsultants.some(c => c.medic.ro.consultant_id === consultant.medic.ro.consultant_id)
                      "
                      class="checkbox-icon checked"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                </div>
                <span class="ml-3 text-sm whitespace-nowrap">{{ consultant.medic.ro.full_name }}</span>
              </div>
            </div>

            <!-- Second and Third Columns -->
            <div
              v-for="columnIndex in [1, 2]"
              :key="columnIndex"
              class="space-y-3"
              :data-test-id="'consultants-column-' + (columnIndex + 1)"
            >
              <div
                v-for="consultant in columnConsultants[columnIndex]"
                :key="consultant.medic.ro.consultant_id"
                class="flex items-center"
                :data-test-id="'consultant-item-' + consultant.medic.ro.consultant_id"
              >
                <div class="medosync-checkbox">
                  <input
                    type="checkbox"
                    :checked="
                      selectedConsultants.some(c => c.medic.ro.consultant_id === consultant.medic.ro.consultant_id)
                    "
                    @change="handleConsultantClick(consultant)"
                    :data-test-id="'consultant-checkbox-' + consultant.medic.ro.consultant_id"
                  />
                  <div
                    class="checkbox-box"
                    :class="{
                      checked: selectedConsultants.some(
                        c => c.medic.ro.consultant_id === consultant.medic.ro.consultant_id
                      )
                    }"
                  >
                    <svg
                      v-if="
                        selectedConsultants.some(c => c.medic.ro.consultant_id === consultant.medic.ro.consultant_id)
                      "
                      class="checkbox-icon checked"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                </div>
                <span class="ml-3 text-sm whitespace-nowrap">{{ consultant.medic.ro.full_name }}</span>
              </div>
            </div>
          </div>

          <!-- Show More Button -->
          <div v-if="showMoreVisible" class="text-center mt-4">
            <button
              @click="handleShowMore"
              class="text-medosync-black text-sm underline hover:opacity-75 transition-opacity"
              data-test-id="show-more-button"
            >
              Show More
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Custom scrollbar styles remain unchanged */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-100 rounded;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300 rounded;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400;
}
</style>
