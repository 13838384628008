<template>
  <div class="my-3" :class="[editMode ? 'mb-5' : 'mb-14']">
    <div class="flex flex-row justify-between items-center">
      <div class="flex flex-row items-center header-title-height">
        <h3 class="s-view-darker-title">Claim {{ claim.hospital_appointment_id }}</h3>
        <div class="ml-20 relative flex items-center">
          <ClaimStatusDropdown :claim="claim" @init-claim="getClaim" />
          <i
            v-if="showMessage"
            class="fa-solid fa-envelope-circle-check fa-2x ml-4 cursor-pointer hover-tooltip"
            @click="openNotificationCentre"
          >
            <div
              class="tooltip absolute bg-medosync-inactive-grey font-sans text-white text-xs w-[120px] right-[20px] mt-2 p-1 font-normal"
            >
              Message(s) from Consultant were assigned to claim.
            </div>
          </i>
        </div>
      </div>

      <Notes :claim="claim" />
    </div>
    <TransitionGroup v-if="!editMode" class="grid grid-cols-6 mt-4" name="fade" tag="div">
      <div key="first-col" class="col-span-2">
        <h4 class="mb-2"><span class="font-medium">Consultant Name: </span>{{ claim.claim_consultant.full_name }}</h4>
        <h5 class="name-wrap mb-2"><span class="font-medium">Patient: </span>{{ claim.patient.full_name }}</h5>
        <h5><span class="font-medium">Insurer: </span>{{ claim.insurance.insurer_name }}</h5>
        <div class="mt-2">
          <span class="font-medium mt-24">Insurer Valid Insurance Contract: </span>
          <RouterLink
            v-if="validInsuranceContract"
            class="underline"
            :to="{
              name: 'InsuranceContracts',
              query: {
                contract_name: validInsuranceContract,
                claim_id: computedClaimId,
                appointment_id: claim.hospital_appointment_id,
                type: computedType
              }
            }"
          >
            {{ validInsuranceContract }}
          </RouterLink>
          <span v-else>None</span>
        </div>
      </div>
      <div key="second-col" class="col-span-1">
        <p class="mb-2">&nbsp;</p>
        <h5 class="mb-2"><span class="font-medium">MRN: </span>{{ claim.patient.mrn }}</h5>
        <div class="flex">
          <h5><span class="font-medium">App.ID: </span>{{ claim.hospital_appointment_id }}</h5>
          <i
            v-if="editPen"
            class="fas fa-pen cursor-pointer ml-2 mt-1"
            @click="openChangeHeaderModal('appid', 'appointment ID', 'App ID')"
          />
        </div>
      </div>

      <div key="third-col" class="col-span-2">
        <p class="mb-2">&nbsp;</p>
        <h5 class="mb-2"><span class="font-medium">Consultant Claim Value: </span>{{ consultantClaimValue }}</h5>
        <h5><span class="font-medium">Hospital Claim Value: </span>{{ hospitalClaimValue }}</h5>
      </div>

      <div
        v-if="claim"
        :class="notesOpened ? 'col-span-5 flex-row justify-end' : 'col-span-1 flex-col'"
        class="flex mb-4"
        :key="notesOpened"
      >
        <ButtonsClaim
          v-if="claim.claim_assoc.assoc_type === 'consultant'"
          :claims="claim"
          @init-claim="getClaim"
          @refresh-files="$emit('refresh-files')"
          :filteredDetails="filteredDetails"
          :allClaimFormsNum="allClaimFormsNum"
        />
        <!-- v-if="isCompositeClaim" -->
        <ButtonsCompositeClaim
          v-if="claim.claim_assoc.assoc_type === 'composite'"
          :claim="claim"
          @init-claim="getClaim"
          @refresh-files="$emit('refresh-files')"
          :filteredDetails="filteredDetails"
          :allClaimFormsNum="allClaimFormsNum"
        />
        <button
          class="self-end flex justify-center items-center bg-[#CBD5E0] rounded-full px-4 py-2 h-10 w-40 text-sm font-medium ml-1"
          @click="contractChangeModal = true"
        >
          Change contract
        </button>
      </div>
    </TransitionGroup>

    <TransitionGroup v-else class="grid grid-cols-6 mt-4" name="fade" tag="div">
      <div key="first-col" class="col-span-2">
        <h5 class="name-wrap"><span class="font-medium">Patient: </span>{{ claim.patient.full_name }}</h5>
      </div>
      <div key="second-col" class="col-span-1">
        <h5><span class="font-medium">MRN: </span>{{ claim.patient.mrn }}</h5>
      </div>
      <div key="third-col" class="col-span-2">
        <h5><span class="font-medium">Insurer: </span>{{ claim.insurance.insurer_name }}</h5>
      </div>
    </TransitionGroup>

    <!-- <div class="grid grid-cols-2 my-3">
      <h4 class="col-span-2 font-medium mb-2">{{ claim.claim_consultant.full_name }}</h4>
      <h5 class="font-medium">Patient Name: <span class="font-normal">{{ claim.patient.full_name }}</span></h5>
      <h5 class="font-medium">MRN: <span class="font-normal">{{ claim.patient.mrn }}</span></h5>
      <h5 class="font-medium">Insurer: <span class="font-normal">{{ claim.insurance.insurer_name }}</span></h5>
      <h5 class="font-medium">App ID: <span class="font-normal">{{ claim.hospital_appointment_id }}</span></h5>
    </div> -->
    <ChangeHeaderModal
      v-if="headerModal"
      :field="changeHeaderInfo"
      @scroll-to-field="scrollToField"
      @close="headerModal = false"
    />

    <ChangeContractModal
      v-if="contractChangeModal"
      :appointment-id="claim.appointment_id"
      :app-id="claim.hospital_appointment_id"
      :insurer-name="claim.insurance.insurer_name"
      :insurer-id="claim.insurance.insurer_id"
      @close="contractChangeModal = false"
      @restart="restartInfo"
    ></ChangeContractModal>
  </div>
  <ToastComponent ref="toast"></ToastComponent>
  <SpinnerLoader v-if="isLoading" data-test-id="spinner-loader" />
</template>

<script>
import ClaimStatusDropdown from './ClaimStatusDropdown.vue';
import Notes from './Notes.vue';
import { notes_store } from '@/store/notes';
import ButtonsClaim from './ButtonsClaim.vue';
import ButtonsCompositeClaim from './ButtonsCompositeClaim.vue';
import ChangeHeaderModal from '@/components/modal/ChangeHeaderModal.vue';
import { OpenAPIJSON } from 'api';
import ChangeContractModal from '@/views/user-management/ChangeContractModal.vue';
import { ToastComponent } from 'ui';
import { withLoadingVue2 } from '@/util/loadingUtilVue2';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';

export default {
  components: {
    ClaimStatusDropdown,
    Notes,
    ButtonsClaim,
    ButtonsCompositeClaim,
    ChangeHeaderModal,
    ChangeContractModal,
    ToastComponent
  },
  props: {
    // claim: output from /search/claim/composite or /search/claim/consultant
    claim: { required: true },
    filteredDetails: null,
    editMode: Boolean,
    allClaimFormsNum: Number
  },
  emits: ['init-claim', 'scroll-to-field', 'refresh-files'],
  data() {
    return {
      openApi: new OpenAPIJSON(),
      headerModal: false,
      changeHeaderInfo: null,
      showMessage: false,
      editPen: false,
      validInsuranceContract: null,
      contractChangeModal: false,
      isLoading: false
    };
  },
  computed: {
    consultantClaimValue() {
      return this.claim.claim_consultant_value == null
        ? 'N/A'
        : parseFloat(this.claim.claim_consultant_value).toFixed(2) + ' €';
    },
    hospitalClaimValue() {
      return this.claim.claim_hospital_value == null
        ? 'N/A'
        : parseFloat(this.claim.claim_hospital_value).toFixed(2) + ' €';
    },
    notesOpened() {
      return notes_store.state.notesOpened;
    },
    computedClaimId() {
      if (this.$route.path.includes('/navigator/consclaim/details/')) {
        return this.claim.claim_consultant_id;
      } else {
        return this.claim.claim_assoc.id;
      }
    },
    computedType() {
      if (this.$route.path.includes('/navigator/consclaim/details/')) {
        return 'consclaim';
      } else {
        return 'compositeclaim';
      }
    }
  },
  mounted() {
    this.loadMoreMessages();
    this.consultant_invoice_get();
  },
  methods: {
    async consultant_invoice_get() {
      try {
        const res = await withLoadingVue2(() => this.openApi.invoice_get(this.claim), this.isLoading, {
          errorMessage: 'Failed to get invoice data'
        });

        if (res) {
          this.invoice = res;
          this.validInsuranceContract = res.prices_from_contract_name;
        } else {
          this.$refs.toast.showToast('Api Error', 'fa-solid fa-triangle-exclamation', 'error');
        }
      } catch (error) {
        this.$refs.toast.showToast(error.message, 'fa-solid fa-triangle-exclamation', 'error');
      }
    },
    openNotificationCentre() {
      this.$router.push({
        name: 'MainNotificationCentre',
        params: {
          id: this.claim.claim_consultant_id,
          hospitalAppointmentId: this.claim.hospital_appointment_id
        }
      });
    },
    async loadMoreMessages() {
      try {
        let notesResponse = await withLoadingVue2(
          async () => {
            if (this.claim.claim_assoc.assoc_type === 'composite') {
              return await this.openApi.claim_composite_message_list(this.claim.claim_assoc.id);
            } else if (this.claim.claim_assoc.assoc_type === 'consultant') {
              return await this.openApi.claim_consultant_message_list(this.claim.claim_assoc.id);
            }
          },
          this.isLoading,
          { errorMessage: 'Failed to load messages' }
        );

        if (notesResponse) {
          let notes = notesResponse.items.filter(note => note.consultant == null);
          this.showMessage = notesResponse.total_items > notes.length;
        }
      } catch (error) {
        console.log('loadMoreMessages: error=', error);
        this.$refs.toast.showToast('Error getting messages', 'fa-solid fa-triangle-exclamation', 'error');
      }
    },
    getClaim() {
      this.$emit('init-claim');
    },
    openChangeHeaderModal(id, fieldName, shortName) {
      this.changeHeaderInfo = {
        id,
        name: fieldName,
        shortName: shortName ?? fieldName
      };
      this.headerModal = true;
    },
    restartInfo() {
      this.$refs.toast.showToast('Contract changed successfully', 'fa-solid fa-check-circle', 'success');
      this.contractChangeModal = false;
      this.consultant_invoice_get();
    },
    scrollToField() {
      this.$emit('scroll-to-field', this.changeHeaderInfo.id);
      this.changeHeaderInfo = null;
      this.headerModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 18px;
}
.name-wrap {
  word-break: break-all;
}
.header-title-height {
  height: 40px;
}

.hover-tooltip {
  position: relative;

  .tooltip {
    visibility: hidden;
  }
}

.hover-tooltip:hover {
  .tooltip {
    visibility: visible;
  }
}

.fade-enter-active {
  transition: all 1.5s;
  transition-timing-function: cubic-bezier(1, -0.16, 0.95, 0.81);
}
.fade-leave-active {
  transition: all 0.65s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
