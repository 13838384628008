<template>
  <BaseModal v-if="showManageModalClaims">
    <template v-slot:header>
      <h3 class="text-lg text-left leading-6 font-medium medo-grey-600">Save all changes made in Claim Form?</h3>
      <br />
      Are you sure you want to save all changes made? If you are unsure whether you want to save the changes made,
      please choose the button “Cancel”.
      <br />
      <br />
      You can then review the claim form again before making a decision.
      <br />

      <div class="modalDelete">
        <div class="ml-auto">
          <button class="file-upload-button-canceled py-2 px-4">Save</button>
          <button class="file-upload-button py-2 px-4" @click="showManageModalClaims = false">Cancel</button>
        </div>
      </div>
    </template>
  </BaseModal>
  <div class="p-4 bg-gray">
    <div class="bg-gray flex relative" v-if="appointmentId && claimId">
      <ClaimFormMenu
        v-if="!formLoading && claimSections && containerId"
        :sections="claimSections"
        :container-id="containerId"
        @set-form-overlay="setFormOverlay"
      />
      <div :class="{ 'form-overlay': formOverlay }" />

      <CompositeVhi
        v-if="claim.claim_form_type.toLowerCase() == 'vhi_hospital'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />

      <CompositeVhiOncology
        v-if="claim.claim_form_type.toLowerCase() == 'vhi_oncology'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />

      <CompositeVhiMri
        v-if="claim.claim_form_type.toLowerCase() == 'vhi_mri'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />

      <CompositeVhiCt
        v-if="claim.claim_form_type.toLowerCase() == 'vhi_c_t'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />

      <CompositeVhiSurgicalProcedure
        v-if="claim.claim_form_type.toLowerCase() == 'vhi_surgical'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />

      <CompositeLaya
        v-if="claim.claim_form_type.toLowerCase() == 'laya_inpatient'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />

      <CompositeGarda
        v-if="claim.claim_form_type.toLowerCase() == 'garda'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />

      <CompositePrisonOfficers
        v-if="claim.claim_form_type.toLowerCase() == 'prison_officers'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapState, mapMutations } from 'vuex';
import { OpenAPIJSON, OpenAPIPDF } from 'api/openapi';
import { getClaimSectionIds, getClaimContainerId } from '../../../util/claims/generateClaimIds';
import ClaimFormMenu from '../details/ClaimFormMenu.vue';
import { BaseModal } from 'ui';
import { withLoadingVue2 } from '@/util/loadingUtilVue2';

export default {
  name: 'DetailsClaimForm',
  props: {
    // claim: { required: true },
    claimInfo: { required: true },
    claimDataPreviousState: { required: false }
  },
  emits: ['form-reload', 'update-header-info', 'set-previous-form-state'],
  components: {
    ClaimFormMenu,
    CompositeVhi: defineAsyncComponent(() => import('../CompositeVhi.vue')),
    CompositeLaya: defineAsyncComponent(() => import('../CompositeLaya.vue')),
    CompositeGarda: defineAsyncComponent(() => import('../CompositeGarda.vue')),
    CompositePrisonOfficers: defineAsyncComponent(() => import('../CompositePrisonOfficers.vue')),
    CompositeVhiCt: defineAsyncComponent(() => import('../CompositeVhiCt.vue')),
    CompositeVhiMri: defineAsyncComponent(() => import('../CompositeVhiMri.vue')),
    CompositeVhiOncology: defineAsyncComponent(() => import('../CompositeVhiOncology.vue')),
    CompositeVhiSurgicalProcedure: defineAsyncComponent(() => import('../CompositeVhiSurgicalProcedure.vue')),
    BaseModal
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      openApiPdf: new OpenAPIPDF(),
      consultantId: null,
      appointmentId: null,
      claimId: null,
      claimSections: null,
      containerId: null,
      formLoading: true,
      showManageModalClaims: false,
      formOverlay: false,
      genders: [],
      diagnosisTypes: [],
      claim_form_id: null,
      claim: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    claimHeight() {
      return this.isTextBoxDisabled ? 'h-[920px]' : 'h-[1020px]';
    }
  },
  created() {
    this.listGenders();
    this.listDiagnosisTypes();
  },
  mounted() {
    this.init();
  },

  methods: {
    ...mapMutations(['setTextBoxDisabled']),
    init() {
      this.formLoading = true;
      this.enableTextBox();
      this.claim_form_composite_list();
    },

    async claim_form_composite_list() {
      try {
        const response = await withLoadingVue2(
          () =>
            this.openApi.claim_form_composite_list({
              claim_ref: {
                type: 'claimid',
                claim_id: this.claimInfo.claim_id
              }
            }),
          this.isLoading,
          { errorMessage: 'Failed to fetch composite claim forms' }
        );

        if (response.claim_forms.length === 0) {
          this.claim_form_id = null;
          return;
        }

        this.claim_form_id = response.claim_forms[0].claim_form_id;
        this.compositeFormGet();
      } catch (e) {
        console.log(e);
      }
    },

    async compositeFormGet() {
      try {
        const items = await withLoadingVue2(
          () => this.openApi.claimform_get(this.claimInfo.app_id, this.claimInfo.claim_id, this.claim_form_id),
          this.isLoading,
          { errorMessage: 'Failed to get claim form data' }
        );

        this.claim = items;
        this.consultantId = this.claim.claim_assoc;
        this.appointmentId = this.claim.appointment_id;
        this.claimId = this.claim.claim_id;
      } catch (e) {
        this.$refs.toast.showToast(e.message || e, 'fa-solid fa-triangle-exclamation', 'error');
      }

      this.getClaimIds(this.claim.claim_form_type);
    },
    async pdfDownloadCompositeForm() {
      try {
        await withLoadingVue2(
          () =>
            this.openApiPdf.claimformPdfPreview({
              appointment_id: this.claimInfo.app_id,
              claim_id: this.claimInfo.claim_id,
              claim_form_id: this.claim_form_id
            }),
          this.isLoading,
          {
            errorMessage: 'Failed to generate PDF preview',
            timeout: 60000 // PDF generation might take longer
          }
        );
      } catch (e) {
        console.log(e);
      }
    },

    handleChildEvent(value) {
      this.$emit('set-previous-form-state', value);
    },

    async listGenders() {
      try {
        this.genders = await withLoadingVue2(() => this.openApi.listPersonGenders(), this.isLoading, {
          errorMessage: 'Failed to load genders list'
        });
      } catch (e) {
        this.$refs?.toast?.showToast(e, 'fa-solid fa-triangle-exclamation', 'error');
      }
    },

    async listDiagnosisTypes() {
      try {
        this.diagnosisTypes = await withLoadingVue2(() => this.openApi.listDiagnosisTypes(), this.isLoading, {
          errorMessage: 'Failed to load diagnosis types'
        });
      } catch (e) {
        this.$refs?.toast?.showToast(e, 'fa-solid fa-triangle-exclamation', 'error');
      }
    },

    disableTextBox() {
      this.setTextBoxDisabled(false);
    },

    enableTextBox() {
      this.setTextBoxDisabled(true);
    },

    formLoaded() {
      this.formLoading = false;
    },

    getClaimIds(value) {
      if (value === 'vhi_hospital') {
        this.claimSections = getClaimSectionIds('vhi_hospital_composite');
        this.containerId = getClaimContainerId('vhi_hospital_composite');
      } else if (value === 'laya_inpatient') {
        this.claimSections = getClaimSectionIds('laya_inpatient_composite');
        this.containerId = getClaimContainerId('laya_inpatient_composite');
      } else if (value === 'garda') {
        this.claimSections = getClaimSectionIds('garda_composite');
        this.containerId = getClaimContainerId('garda_composite');
      } else if (value === 'prison_officers') {
        this.claimSections = getClaimSectionIds('prison_officers_composite');
        this.containerId = getClaimContainerId('prison_officers_composite');
      } else if (value === 'vhi_c_t') {
        this.claimSections = getClaimSectionIds('vhi_c_t_composite');
        this.containerId = getClaimContainerId('vhi_c_t_composite');
      } else if (value === 'vhi_mri') {
        this.claimSections = getClaimSectionIds('vhi_mri_composite');
        this.containerId = getClaimContainerId('vhi_mri_composite');
      } else if (value === 'vhi_oncology') {
        this.claimSections = getClaimSectionIds('vhi_oncology_composite');
        this.containerId = getClaimContainerId('vhi_oncology_composite');
      } else if (value === 'vhi_surgical') {
        this.claimSections = getClaimSectionIds('vhi_surgical_composite');
        this.containerId = getClaimContainerId('vhi_surgical_composite');
      }
    },

    setFormOverlay(bool) {
      this.formOverlay = bool;
    },

    updateHeaderInfo(data) {
      this.$emit('update-header-info', data);
    }
  }
};
</script>

<style>
.bg-gray {
  background-color: #d9d9d9;
}

.cancel-button,
.save-button {
  background: #00a7e1;
  border-radius: 50px;
  width: 95px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  margin: 10px;
  margin-top: 34px;
  margin-bottom: 34px;
}

.cancel-button {
  background-color: #cbd5e0;
  color: #fff;
  margin-left: 100px;
}

.file-upload-button {
  background: #00a7e1;
  border-radius: 50px;
  width: 95px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  margin: 10px;
}

.file-upload-button-canceled {
  border: 2px solid #00a7e1;
  border-radius: 50px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
}

.form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(25, 25, 25, 0.6);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
